<template>
  <div class="console game-over with-border with-border-radius text-center">

    <div style="transform: rotate(90deg);">
      <i class="fas fa-coin fa-6x margin-y-2xs coinflip"></i>
    </div>

    <h1 class="margin-top-0 padding-top-xs">Game Over</h1>

    <p style="padding: 20px;color: white;">
      {{currentGame.game_over_text}}
    </p>

    <game-stats />
    <achievements />
    <button @click.prevent="gotoRoom('gamecredits')" class="white margin-right-md">Credits</button>
    <button @click="playAgain()" class="green">Play Again <i class="fas fa-arrow-right"></i> </button>
  </div>
</template>

<script>
import Achievements from '../../components/Achievements.vue'
import GameStats from '../../components/GameStats.vue'
import GameMixin from '../../mixins/GameMixin'
import { createNewGame } from '../../utils/game'

export default {
  components: { GameStats, Achievements },
  name: "GameOver",

  mixins: [
    GameMixin
  ],

  created () {
    this.$plausible.trackEvent('game-over')
  },

  methods: {
    playAgain () {
      this.$plausible.trackEvent('play-again')
      createNewGame(this.currentShip)
    },

    gotoRoom (room) {
      this.clearMessages()
      this.changeDisplay(room)
    },
  }
}
</script>
