<template>
  <div class="row">
    <div class="console with-border with-border-radius">
      <div class="scene-traveling" style="height: 500px;">
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>

        <font-awesome-icon :icon="['fad', 'fire-flame-simple']" class="size-4xl rocket-fire" :transform="this.burnTransform" />

        <font-awesome-layers class="size-4xl">
          <font-awesome-icon v-if="this.currentShip.icon == 'rocket'" :icon="['fad', 'rocket']" :transform="this.rocketTransform" @click="startAirlockAnimation()" />
          <font-awesome-icon v-if="this.currentShip.icon == 'rv'" :icon="['fad', 'rv']" :transform="this.iconTransform" />
          <font-awesome-icon v-if="this.currentShip.icon == 'starship-freighter'" :icon="['fad', 'starship-freighter']" :transform="this.iconTransform" />
          <font-awesome-icon v-if="this.currentShip.icon == 'starship'" :icon="['fas', 'starship']" :transform="this.iconTransform" />
          <font-awesome-icon v-if="this.currentShip.icon == 'ufo'" :icon="['fad', 'ufo']" :transform="this.iconTransform" />

          <font-awesome-icon v-if="showDamage" :icon="['fad', 'chart-scatter-3d']" style="--fa-primary-color: black; --fa-primary-opacity: 1.0; --fa-secondary-opacity: 0.0;" :transform="this.damageTransform" />
          <font-awesome-icon v-if="showDamageExtra" :icon="['fad', 'chart-scatter-3d']" style="--fa-primary-color: black; --fa-primary-opacity: 1.0; --fa-secondary-opacity: 0.0;" :transform="this.extraDamageTransform" />
        </font-awesome-layers>

        <font-awesome-icon v-if="showAirlockAnimation" class="size-lg fa-spin" :icon="['fad', 'person-falling']" :transform="this.airlockTransform" />

        <div class="event-warning" v-show="showEventWarning">
          <h3 class="event-title warning-message">{{ computerMessage }}</h3>
          <button @click="gotoEvent()" class="size-sm margin-top-lg">Click to continue</button>
        </div>
      </div>

      <div class="status-panel" id="messagePanel">
        <div v-for="message in this.messages" :key="message.id" class="console-message" v-html="`<i class='far fa-angle-right'></i> ${message.text}`">
        </div>
        <span class="console-message-cursor">&nbsp;</span>
      </div>
    </div>
  </div>
</template>

<script>
const MIN_DOWN = 74 // 68
const MAX_DOWN =78 // 72
const RIGHT_OFFSET = 60 // 40
const TRAVEL_TIME = 100 // in miliseconds
const STARTING_EVENT_CHANCE = 10
const ANIMATION_H_OFFSET = 110
const ANIMATION_V_OFFSET = 110

import GameMixin from '../mixins/GameMixin'
import { endGame, updateGame } from '../utils/game'
import { getMessagesByType } from '../utils/message'
import settings from '../utils/settings'

export default {
  name: "Travel",

  mixins: [
    GameMixin
  ],

  data() {
    return {
      eventTimer: '',
      eventTrigger: false,
      eventTickCount: 0,
      eventChance: STARTING_EVENT_CHANCE,
      rocketTimer: '',
      rocketRotation: 45,
      rocketDown: 70,
      rocketRight: RIGHT_OFFSET,
      airlockAnimationTimer: '',
      airlockAnimationDown: 0,
      airlockAnimationRight: 0,
      move: -1,
      message: '',
      status: '',
      showWarning: false,
      arrivedAtOupost: false,
      showAirlockAnimation: false
    }
  },

  created() {
    const eventSpawnTime = TRAVEL_TIME

    if (this.currentGame.current_event_id > 0 && !this.currentGame.event_resolved) {
      this.changeDisplay("events")
    } else {
      this.rocketTimer = setInterval(this.updateRocket, 200)
      this.eventTimer = setInterval(this.spawnEvent, eventSpawnTime)

      if (this.currentGame.airlock_animation) {
        this.startAirlockAnimation()
      }
    }
  },

  computed: {
    rocketTransform () {
      return `down-${this.rocketDown} right-${this.rocketRight} rotate-${this.rocketRotation}`
    },

    damageTransform () {
      return `down-${this.rocketDown + 3} right-${this.rocketRight + 2} rotate-20`
    },

    extraDamageTransform () {
      return `down-${this.rocketDown + 2} right-${this.rocketRight + 1} rotate-60`
    },

    airlockTransform () {
      return `down-${this.airlockAnimationDown} right-${this.airlockAnimationRight}`
    },

    iconTransform () {
      return `down-${this.rocketDown} right-${this.rocketRight}`
    },

    burnTransform () {
      return `rotate-270 right-${this.rocketRight} down-${this.rocketDown}`
    },

    computerMessage () {
      return this.message
    },

    showEventWarning () {
      return (this.message != '')
    },

    showDamage () {
      return this.currentShip.condition == settings.SHIP_CONDITION_POOR || this.currentShip.condition == settings.SHIP_CONDITION_CRITICAL
    },

    showDamageExtra () {
      return this.currentShip.condition == settings.SHIP_CONDITION_CRITICAL
    },

    messages () {
      return getMessagesByType('status')
    }
  },

  methods: {
    startAirlockAnimation () {
      if (!this.showAirlockAnimation) {
        // clear animation flag
        updateGame(this.currentGame, { airlock_animation: false })

        // reset icon location
        this.airlockAnimationDown = ANIMATION_V_OFFSET + this.rocketDown
        this.airlockAnimationRight = ANIMATION_H_OFFSET
        this.showAirlockAnimation = true

        // Start timer
        this.airlockAnimationTimer = setInterval(this.updateAirlockAnimation, 40)
      }
    },

    updateAirlockAnimation () {
      this.airlockAnimationRight = this.airlockAnimationRight - 1
      this.airlockAnimationDown = this.airlockAnimationDown + 1

      if (this.airlockAnimationDown > 400) {
        this.showAirlockAnimation = false
        clearInterval(this.airlockAnimationTimer)
      }
    },

    updateRocket () {
      if (Math.random() > .2)
      {
        if (this.rocketDown > MAX_DOWN) {
          this.move = -1
        }

        if (this.rocketDown < MIN_DOWN) {
          this.move = 1
        }

        if (this.move > 0) {
          this.rocketDown = this.rocketDown + 1
        } else {
          this.rocketDown = this.rocketDown - 1
        }

        if (this.rocketRight == RIGHT_OFFSET) {
          this.rocketRight = this.rocketRight + 1
        } else {
          this.rocketRight = RIGHT_OFFSET
        }
      }
    },

    accessComputer () {
      this.clearMessages()
      if (!this.isGameOver()) {
        this.changeDisplay("shipmenu")
      }
    },

    spawnEvent () {
      this.eventTickCount = this.eventTickCount + 1
      const arrived = this.shipTravel(TRAVEL_TIME)

      if (!this.isGameOver()) {
        if (arrived) {
          clearInterval(this.eventTimer)
          this.showWarning = true
          this.eventTickCount = 0 // Prevent event code from running
          this.message = `Arrived at ${this.currentGame.destination_planet}`
        }

        if (this.eventTickCount > 10) {
          this.eventTickCount = 0

          this.systemsCheck()
          this.minorEventCheck()
          // Update crew -> consume food & update health
          this.allCrewUpdate()
          this.incrementStarDate()

          if (this.currentGame.distance_to_outpost <= 0) {
            clearInterval(this.eventTimer)
            this.showWarning = true
            this.arrivedAtOupost = true
            this.message = `Arrived at ${this.currentGame.outpost_name}`
            updateGame(this.currentGame, { outposts_visited: this.currentGame.outposts_visited + 1 })
            this.$plausible.trackEvent('outpost')

          } else {
            // Random chance of an event happening every tick (chance increases each time)
            const eventSpawns = (Math.random() * 100 < this.eventChance  ? true : false)

            if (this.checkForLowFuel()) {
              clearInterval(this.eventTimer)
              this.showWarning = true
              this.message = `Warning: ${this.currentEvent.warning_title}`

            } else if (this.checkForMutiny() == true || this.checkForHungerStrike() == true) {
              clearInterval(this.eventTimer)
              this.showWarning = true
              this.message = `Warning: ${this.currentEvent.warning_title}`

            } else if (this.checkForMurderousRampage()) {
              clearInterval(this.eventTimer)
              this.showWarning = true
              this.message = `Warning: ${this.currentEvent.warning_title}`

            } else if (eventSpawns) {
              clearInterval(this.eventTimer)
              const currentEvent = this.randomEvent()
              this.showWarning = true
              this.arrivedAtOupost = false
              this.message = `Warning: ${currentEvent.warning_title}`

            } else {
              // No event this time. Increase the chance for the next check
              this.eventChance = this.eventChance + Math.floor(Math.random() * 5) + 5
              this.burnFuel()
            }
          }
        }
      }
      this.scrollMessageBox()
    },

    scrollMessageBox () {
      const messagePanel = document.getElementById("messagePanel")
      messagePanel.scrollTop = messagePanel.scrollHeight;
    },

    gotoEvent () {
      this.clearMessages()

      if (!this.isGameOver()) {
        if (this.arrivedAtOupost) {
          this.changeDisplay("outpost")
        } else {
          this.changeDisplay("events")
        }
      } else {
        if (this.getLivingCrewMembers(0, this.currentShip).length === 0) {
          endGame(false, "You can't run a ship without a crew. Better luck next time.")
        } else {
          endGame(true, `You arrive safely on ${this.currentGame.destination_planet}. Mission Complete!`)
        }
      }
    },

    continueGame () {
      this.clearMessages()
      if (!this.isGameOver()) {
        this.changeDisplay("events")
      }
    },
  },

  beforeDestroy () {
    clearInterval(this.rocketTimer)
    clearInterval(this.eventTimer)
    clearInterval(this.airlockAnimationTimer)
  }
}
</script>

<style lang="scss">
// n is number of stars required
@function multiple-box-shadow ($n) {
  $value: '#{random(2000)}px #{random(2000)}px #FFF';
  @for $i from 2 through $n {
    $value: '#{$value} , #{random(2000)}px #{random(2000)}px #FFF';
  }

  @return unquote($value)
}

$shadows-small:  multiple-box-shadow(700);
$shadows-medium: multiple-box-shadow(200);
$shadows-big:    multiple-box-shadow(100);

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $shadows-small;
  animation: animStar 50s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadows-small;
  }
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $shadows-medium;
  animation: animStar 100s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $shadows-medium;
  }
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $shadows-big;
  animation: animStar 150s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shadows-big;
  }
}

.status-panel {
  margin-top: 16px;
  padding-top: 8px;
  height: 100px;
  overflow-y: scroll;
}

@keyframes animStar {
  from {
    transform: translateX(0px)
  }

  to {
    transform: translateX(-2000px)
  }
}
</style>
