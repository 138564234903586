import Vue from 'vue'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'

import GameState from './models/GameState'
import Achievement from './models/Achievement'
import Ship from './models/Ship'
import Name from './models/Name'
import Crew from './models/Crew'
import Job from './models/Job'
import Event from './models/Event'
import Choice from './models/Choice'
import Result from './models/Result'
import Disease from './models/Disease'
import Message from './models/Message'
import MinorEvent from './models/MinorEvent'
import Enemy from './models/Enemy'
import Phrase from './models/Phrase'
import Planet from './models/Planet'
import ComMessageChoice from './models/ComMessageChoice'
import ComMessage from './models/ComMessage'
import Cheat from './models/Cheat'
import ResearchData from './models/ResearchData'
import MazeRoom from './models/MazeRoom'

Vue.use(Vuex)

const database = new VuexORM.Database()

database.register(Achievement)
database.register(Crew)
database.register(Cheat)
database.register(Choice)
database.register(ComMessage)
database.register(ComMessageChoice)
database.register(Disease)
database.register(Event)
database.register(Enemy)
database.register(GameState)
database.register(Job)
database.register(MazeRoom)
database.register(Message)
database.register(MinorEvent)
database.register(Name)
database.register(Phrase)
database.register(Planet)
database.register(ResearchData)
database.register(Result)
database.register(Ship)

const store = new Vuex.Store({
  plugins: [VuexORM.install(database)]
})

export default store
