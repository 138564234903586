<template>
  <div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="boxes-stacked" />
        Sell Supplies
      </h3>

      You have <span class="game-info">{{ credits }}</span> credits.

      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        {{message.text}}
      </div>

      <div id="output">
        <div class="choices">
          <ul class="fa-ul">
            <li v-if="this.currentShip.food > 100">
              <span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="sell('food', 100)">Sell 100 food units [+{{ calculateSellPrice('food', 100) }} credits]</a>
            </li>
            <li v-if="this.currentShip.repair_kits > 0">
              <span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="sell('repairkit', 1)">Sell repair kit [+{{ calculateSellPrice('repairkit', 1) }} credits]</a>
            </li>
            <li v-if="this.currentShip.med_kits > 0">
              <span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="sell('medkit', 1)">Sell med kit [+{{ calculateSellPrice('medkit', 1) }} credits]</a>
            </li>
            <li v-if="this.currentShip.alien_artifacts > 0">
              <span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="sell('artifact', 1)">Sell Alien Artifacts [+{{ calculateSellPrice('artifact', 1) }} credits]</a>
            </li>
            <li v-if="this.currentShip.erbium_crystals > 0">
              <span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="sell('erbium', 1)">Sell erbium crystal [+{{ calculateSellPrice('erbium', 1) }} credits]</a>
            </li>
          </ul>

          <div><a href="#" @click.prevent="returnToOutpost()">Return to Space Station</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../../mixins/GameMixin'
import { itemSellAmountCalculation } from '../../utils/formulas'

export default {
  name: "SellSupplies",

  mixins: [
    GameMixin
  ],

  computed: {
    credits () {
      return this.currentShip.credits.toLocaleString()
    }
  },

  methods: {
    returnToOutpost () {
      this.clearMessages()
      this.changeDisplay("outpost")
    },

    sell (item, quantity) {
      this.clearMessages()

      const cost = this.calculateSellPrice(item, quantity)

      switch (item)
      {
        case 'repairkit':
          this.sellRepairKits(quantity, cost)
          break;
        case 'medkit':
          this.sellMedKits(quantity, cost)
          break;
        case 'food':
          this.sellFood(quantity, cost)
          break;
        case 'erbium':
          this.sellErbiumCrystal(quantity, cost)
          break;
        case 'artifact':
          this.sellAlienArtifact(quantity, cost)
          break;
      }
    },

    calculateSellPrice (item, quantity) {
      return itemSellAmountCalculation(item, quantity, this.currentGame)
    }
  }
}
</script>
