<template>

<div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="gas-pump" />
        Refuel Ship
      </h3>

      <div>
        Your ship's fuel level is at {{this.currentShip.fuel_amount}}% and weapons energy is at {{this.currentShip.weapon_status}}%.
        <p>You have <span class="game-info">{{ credits }}</span> credits.</p>
      </div>

      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        {{message.text}}
      </div>

      <div id="output">
        <div class="choices">
          <ul class="fa-ul">
            <li v-if="this.currentShip.fuel_amount < 100">
              <span class="fa-li"><i class="fas fa-square"></i></span>
              <a href="#" @click.prevent="refuelShip()">Refuel the ship [{{this.refuelCost}} credits]</a>
            </li>

            <li v-if="this.currentShip.fuel_amount < 100 && fuelCost(10) <= this.currentShip.credits">
              <span class="fa-li"><i class="fas fa-square"></i></span>
              <a href="#" @click.prevent="partialRefuelShip(10)">Refuel the ship by 10% [{{ fuelCost(10) }} credits]</a>
            </li>

            <li v-if="this.currentShip.weapon_status < 100">
              <span class="fa-li"><i class="fas fa-square"></i></span>
              <a href="#" @click.prevent="rechargeWeapons()">Recharge the weapons [{{this.weaponRechargeCost}} credits]</a>
            </li>

          </ul>

          <div><a href="#" @click.prevent="gotoRoom('outpost')">Return to Outpost</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../../mixins/GameMixin'
import { refuelCostCalculation, weaponRechargeCostCalculation, fuelCostCalculation } from '../../utils/formulas'
import { addMessage, clearMessages } from '../../utils/message'

export default {
  name: "OutpostRefuel",

  mixins: [
    GameMixin
  ],

  computed: {
    refuelCost () {
      return refuelCostCalculation(this.currentShip.fuel_amount)
    },

    weaponRechargeCost () {
      return weaponRechargeCostCalculation(this.currentShip.weapon_status)
    },

    credits () {
      return this.currentShip.credits.toLocaleString()
    }
  },

  methods: {
    gotoRoom (room) {
      clearMessages()
      this.changeDisplay(room)
    },

    fuelCost (amount) {
      return fuelCostCalculation(amount)
    },

    refuelShip () {
      clearMessages()
      if (this.currentShip.credits >= this.refuelCost) {
        this.outpostRefuel(this.refuelCost, 100)
      } else {
        addMessage("You don't have enough credits.", "lose-message")
      }
    },

    partialRefuelShip (percentAmount) {
      clearMessages()
      const cost = fuelCostCalculation(percentAmount)
      if (this.currentShip.credits >= cost) {
        const fuelAmount = this.currentShip.fuel_amount + percentAmount
        this.outpostRefuel(cost, fuelAmount)
      } else {
        addMessage("You don't have enough credits.", "lose-message")
      }
    },

    rechargeWeapons () {
      clearMessages()
      if (this.currentShip.credits >= this.weaponRechargeCost) {
        this.outpostWeaponRecharge(-this.weaponRechargeCost)
      } else {
        addMessage("You don't have enough credits.", "lose-message")
      }
    }
  }
}
</script>
