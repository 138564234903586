import GameState from '../store/models/GameState'
import Ship from '../store/models/Ship'
import Crew from '../store/models/Crew'
import Event from '../store/models/Event'
import Result from '../store/models/Result'
import Message from '../store/models/Message'

import settings from '../utils/settings'
import { getRandomRecord, getRandomName, valueLimit, zeroLimit } from '../utils/helpers'
import { clearMessages, addMessage, addStatusMessage, getMessagesByType } from '../utils/message'
import { setCrewDisease, cureCrewDisease, updateCrewDisease, updateCrewHunger, getRandomCrewMember, getRandomHealthyCrewMemberExcluding, getLivingCrewMembers,
  getStarvingCrewMembers, getCrewMemberByPosition, updateCrewHealth, getFeelingDescription, updateCrew, addCrew, crewDeath, updateCrewDrunkenness, updateAllCrewDrunkenness,
  transformCrewIntoEvilEye, setCrewMurderousRampage, getRampagingCrewMembers, getRandomRampagingCrewMember, getRandomCrewMemberExcluding, endCrewMurderousRampage,
  setCrewPanic, endCrewPanic, feedCrew, createCyborgCrew } from '../utils/crew'
import { endGame, updateGame } from '../utils/game'
import { repairShipHull, repairShipEngine, updateShip, repairShipAirlock, damageShipAirlock, repairShipWasteDisposal, repairShipWeaponsSystem, repairShipNavigationSystem,
  damageShipWasteDisposal, damageShipNavigationSystem, damageShipWeaponsSystem } from '../utils/ship'
import { getRandomDisease, getDiseaseById, passInfectionCalculation } from '../utils/disease'
import { doctorHealingMultipler, fuelConsumptionCalculation, distanceTraveledCalculation, hungerStrikeThreshold } from '../utils/formulas'
import { winningEscapeResults, losingEscapeResults } from '../utils/combat'
import { pilotingCheck, mechanicsCheck, navigationCheck, medicalCheck, cookingCheck, weaponsCheck, scienceCheck,
  getRandomDifficultyLevel, getDifficultyDescription, getMedicalDifficultyDescription, getCollisionDifficultyDescription,
  getCombatDifficultyDescription, getPlanetDifficultyDescription, getWormholeDifficultyDescription, getSimpleDifficultyDescription } from '../utils/skills'
import { cloneCrewMember } from '../utils/crew'
import resolveToString from 'es6-template-strings'
import Choice from '../store/models/Choice'
import Enemy from '../store/models/Enemy'
import { applyMinorEventEffects, getMinorEventCrewMember, getMinorEventText, getRandomMinorEvent } from '../utils/minorevent'
import { getActiveJobs, updateJob } from '../utils/job'
import { buildRepairKit, crystalScan, synthesizeMedKits } from '../utils/science'
import { replicateFood } from '../utils/cooking'
import { formatCrewPhrase, getStarvingChat } from '../utils/phrases'
import { lockAchievement, unlockAchievement } from '../utils/achievements'
import { getRandomPlanetName } from '../utils/planets'
import { airlockCrewMember } from '../utils/airlock'

export default {
  computed: {
    currentGame () {
      return GameState.query().first()
    },

    currentShip () {
      return Ship.query().with('crew_members').first()
    },

    currentEvent () {
      const event = Event.query().whereId(this.currentGame.current_event_id).with('choices').first()

      if (!this.loaded) {
        this.loadEvent()
      }

      return event
    },

    eventChoices () {
      return this.getChoiceList()
    },

    currentResult () {
      return this.result
    },

    currentMessages () {
      return getMessagesByType('default')
    },

    currentCrewBio () {
      return Crew.query().whereId(this.currentGame.selected_crew_id).first()
    },

    travelDaysRemaining () {
      return Math.floor(this.currentGame.distance_to_planet / settings.DISTANCE_PER_DAY)
    }
  },

  data () {
    return {
      result: null,
      enemy: null,
      crewMember: null,
      targetCrewMember: null,
      performingSkillCrewMember: null,
      difficulty: 0,
      loaded: false,
      messageTokens: {}
    }
  },

  methods: {
    changeDisplay (display) {
      GameState.update({
        where: this.currentGame.id,
        data: { active_display: display }
      })
    },

    clearMessages () {
      Message.deleteAll()
    },

    travel () {
      GameState.update({
        where: this.currentGame.id,
        data: {
          status: 'travel'
        }
      })
    },

    systemsCheck () {
      if (this.currentShip.condition === settings.SHIP_CONDITION_CRITICAL) {
        addStatusMessage('Warning: Ship in critical condition.', 'lose-message', true)
      }

      if (this.currentShip.fuel_amount < 10) {
        addStatusMessage('Warning: Fuel critical.', 'lose-message', true)
      } else if (this.currentShip.fuel_amount < 20) {
        addStatusMessage('Warning: Low fuel.', 'lose-message', true)
      }

      if (this.currentShip.weapon_status < 10) {
        addStatusMessage('Warning: Low weapons power.', 'lose-message', true)
      }

      if (this.currentShip.food > 100 && this.currentShip.food < 500) {
        const cook = getCrewMemberByPosition(settings.POSITION_COOK, this.currentShip)

        if (cook && this.currentGame.food_ration_level == settings.FOOD_RATION_FILLING) {
          addStatusMessage(formatCrewPhrase(cook , 'Captain, food is running low. You should change the ration levels.'), 'lose-message', true)
        }
      }

      if (this.currentShip.food > 0 && this.currentShip.food < 100) {
        addStatusMessage('Warning: Food level critical.', 'lose-message', true)
      }
    },

    minorEventCheck () {
      if (Math.floor(Math.random() * 100) < settings.MINOR_EVENT_CHANCE) {
        const minorEvent = getRandomMinorEvent('travel')
        const crewMember = getMinorEventCrewMember(minorEvent, this.currentShip)

        // Make sure there is a crewMember - position could be dead
        if (crewMember) {
          applyMinorEventEffects(minorEvent, this.currentShip, crewMember, this.currentGame)
          addStatusMessage(getMinorEventText(minorEvent, crewMember))
        }
      }
    },

    updateJobs (distanceTraveled) {
      const jobs = getActiveJobs()

      jobs.forEach(job => {
        const distance = job.distance_til_complete - distanceTraveled

        if (job.crew.status == settings.STATUS_DEAD) {
          updateJob(job, { active: false })
        } else {
          if (distance <= 0) {
            updateJob(job, { active: false })

            switch (job.job_type) {
              case settings.JOB_MEDKIT:
                synthesizeMedKits(job.crew, this.currentShip, this.currentGame)
                break

              case settings.JOB_FOOD:
                replicateFood(job.crew, this.currentShip, job.value, this.currentGame)
                break

              case settings.JOB_REPAIRKIT:
                buildRepairKit(job.crew, this.currentShip, job.value, this.currentGame)
                break

              case settings.JOB_CRYSTAL:
                crystalScan(job.crew, this.currentShip, this.currentGame)
                break
            }
          } else {
            updateJob(job, { distance_til_complete: distance })
          }
        }
      })
    },

    updateOnlyDistance (distanceTraveled) {
      // Special function for instant travel through wormhole. Doesn't update cooldowns.
      GameState.update({
        where: this.currentGame.id,
        data: {
          distance_traveled: this.currentGame.distance_traveled + distanceTraveled,
          distance_to_planet: zeroLimit(this.currentGame.distance_to_planet - distanceTraveled),
          distance_to_outpost: this.currentGame.distance_to_outpost - distanceTraveled
        }})

      this.checkTravelAchievement()
    },

    updateDistance (distanceTraveled) {
      GameState.update({
        where: this.currentGame.id,
        data: {
          distance_traveled: this.currentGame.distance_traveled + distanceTraveled,
          distance_to_planet: zeroLimit(this.currentGame.distance_to_planet - distanceTraveled),
          distance_to_outpost: this.currentGame.distance_to_outpost - distanceTraveled,
          cook_cooldown: zeroLimit(this.currentGame.cook_cooldown - distanceTraveled),
          doctor_cooldown: zeroLimit(this.currentGame.doctor_cooldown - distanceTraveled),
          mechanic_cooldown: zeroLimit(this.currentGame.mechanic_cooldown - distanceTraveled),
          navigator_cooldown: zeroLimit(this.currentGame.navigator_cooldown - distanceTraveled),
          pilot_cooldown: zeroLimit(this.currentGame.pilot_cooldown - distanceTraveled),
          scientist_cooldown: zeroLimit(this.currentGame.scientist_cooldown - distanceTraveled),
          weapons_cooldown: zeroLimit(this.currentGame.weapons_cooldown - distanceTraveled),
          spaceball_cooldown: zeroLimit(this.currentGame.spaceball_cooldown - distanceTraveled),
          lowfuel_warning_cooldown: zeroLimit(this.currentGame.lowfuel_warning_cooldown - distanceTraveled),
          rampage_cooldown: zeroLimit(this.currentGame.rampage_cooldown - distanceTraveled),
        }})

      this.checkTravelAchievement()
    },

    checkTravelAchievement () {
      switch (true) {
        case this.currentGame.distance_traveled >= 500000:
          unlockAchievement("500000")
          lockAchievement("200000")
          break

        case this.currentGame.distance_traveled >= 200000:
          unlockAchievement("200000")
          lockAchievement("100000")
          break

        case this.currentGame.distance_traveled >= 100000:
          unlockAchievement("100000")
          break
      }
    },

    incrementStarDate () {
      if (this.currentGame.day == settings.DAYS_PER_YEAR) {
        updateGame(this.currentGame, { year: this.currentGame.year + 1, day: 1 })
      } else {
        updateGame(this.currentGame, { day: this.currentGame.day + 1 })
    }
    },

    shipTravel (time) {
      // Add random amount to travel so the end of the number isn't always 000 - just looks better.
      const distanceTraveled = distanceTraveledCalculation(this.currentShip.speed, time) + Math.floor(Math.random() * 10)
      this.updateDistance(distanceTraveled)
      this.updateJobs(distanceTraveled)

      return (this.currentGame.distance_to_planet <= 0) // returns true when arriving at the destination
    },

    burnFuel () {
      const fuelBurned = fuelConsumptionCalculation(this.currentShip)
      const new_fuel_amount = this.currentShip.fuel_amount - fuelBurned
      updateShip(this.currentShip, { fuel_amount: new_fuel_amount })
      // console.log("fuelBurned", fuelBurned, "this.currentShip.fuel_amount", this.currentShip.fuel_amount)
    },

    checkForLowFuel () {
      // console.log('fuel', this.currentShip.fuel_amount, 'cooldown', this.currentGame.lowfuel_warning_cooldown)
      if (this.currentShip.fuel_amount <= settings.LOW_FUEL_EVENT_THRESHOLD && this.currentGame.lowfuel_warning_cooldown === 0) {
        const event = Event.query().where((event) => {
          return event.title == 'Low Fuel'
        }).first()

        GameState.update({
          where: this.currentGame.id,
          data: {
            current_event_id: event.id,
            event_resolved: false,
            lowfuel_warning_cooldown: settings.LOW_FUEL_EVENT_COOLDOWN_DISTANCE
          }
        })

        return true
      } else {
        return false
      }
    },

    checkForHungerStrike () {
      const crewMembers = getLivingCrewMembers(0, this.currentShip)
      let totalDays = 0

      crewMembers.forEach(crew => totalDays += crew.days_since_last_meal)
      const threshold = hungerStrikeThreshold(crewMembers.length)

      if (totalDays > threshold) {
        const event = Event.query().where((event) => {
          return event.title == 'Hunger Strike'
        }).first()

        GameState.update({
          where: this.currentGame.id,
          data: {
            current_event_id: event.id,
            event_resolved: false,
          }
        })

        return true
      }

      return false
    },

    checkForMutiny () {
      if (this.currentGame.crew_airlock_count >= settings.AIRLOCK_MUTINY_THRESHOLD) {
        const event = Event.query().where((event) => {
          return event.title == 'Mutiny'
        }).first()

        GameState.update({
          where: this.currentGame.id,
          data: {
            current_event_id: event.id,
            event_resolved: false,
          }
        })

        return true
      }

      return false
    },

    checkForMurderousRampage () {
      const rampagingCrew = getRampagingCrewMembers(this.currentShip)

      if (rampagingCrew.length > 0 && this.currentGame.rampage_cooldown === 0) {
        const remainingCrew = getLivingCrewMembers(0, this.currentShip)

        if (remainingCrew.length > 1) {
          // Start Robot Rampage event
          const event = Event.query().where((event) => {
            return event.title == 'Robot Rampage'
          }).first()

          GameState.update({
            where: this.currentGame.id,
            data: {
              current_event_id: event.id,
              event_resolved: false,
              rampage_cooldown: settings.RAMPAGE_COOLDOWN_DISTANCE
            }
          })
          return true

        } else {
          // Calm down since there is no one to rampage against
          const crew = remainingCrew[0]
          endCrewMurderousRampage(crew)
          addStatusMessage(`${crew.name} calms down.`, 'success-message', true)
          return false
        }
      }

      return false
    },

    allCrewUpdate () {
      const crewMembers = getLivingCrewMembers(0, this.currentShip)
      crewMembers.forEach(crew => this.updateCrew(crew))

      if (getStarvingCrewMembers(this.currentShip).length > 0) {
        addStatusMessage("You have run out of food. Your crew is starving.", 'lose-message', true)

        if (Math.floor(Math.random() * 100) < 20) {
          const starvingChat = getStarvingChat(getRandomCrewMember(this.currentShip))
          addStatusMessage(starvingChat, 'lose-message', true)
        }
      }
    },

    randomEvent () {
      const chance = Math.floor(Math.random() * 100)
      const events = Event.query().where((event) => {
        if (this.currentShip.airlock_status == settings.AIRLOCK_STATUS_DAMAGED && event.event_type == 'airlock') {
          return false // prevent airlock event when airlock is broken
        }

        if (this.currentShip.waste_disposal == settings.WASTE_DISPOSAL_STATUS_DAMAGED && event.event_type == 'waste-disposal') {
          return false // prevent waste disposal event when it is broken
        }

        return event.probability > chance
          && event.id != this.currentGame.last_event_id // prevents same event from happening twice in a row. Comment out this line when testing single event.
      }).all()

      const event = getRandomRecord(events)

      GameState.update({
        where: this.currentGame.id,
        data: {
          event_count: this.currentGame.event_count + 1,
          current_event_id: event.id,
          event_resolved: false,
        }
      })

      return event
    },

    endEvent () {
      GameState.update({
        where: this.currentGame.id,
        data: {
          last_event_id: this.currentGame.current_event_id,
          current_event_id: 0
        }
      })
    },

    markEventResolved () {
      GameState.update({
        where: this.currentGame.id,
        data: { event_resolved: true }
      })
    },

    loadEvent () {
      this.loaded = true
      this.result = null
      this.crewMember = null
      this.targetCrewMember = null
      this.messageTokens = {}

      // Check to see if the game is over
      if (!this.isGameOver()) {
        const event = this.currentEvent

        if (event.difficulty < 0) {
          this.difficulty = getRandomDifficultyLevel(this.currentGame)
        }

        this.crewMember = getRandomCrewMember(this.currentShip)
        this.messageTokens.crew_member = this.crewMember.name
        this.messageTokens.crew_position = this.crewMember.position
        this.messageTokens.difficulty_description = getDifficultyDescription(this.difficulty)
        this.messageTokens.food = getRandomName("food")
        this.messageTokens.foods = getRandomName("food", true)

        // Add crew member names to message tokens
        getLivingCrewMembers(0, this.currentShip).forEach(crew => {
          this.messageTokens[crew.position.toLowerCase()] = crew.name
        })

        switch (this.currentEvent.event_type) {
          case "airlock":
            damageShipAirlock(this.currentShip)
            this.messageTokens.ship_part = getRandomName("shippart")
            break

          case "waste-disposal":
            damageShipWasteDisposal(this.currentShip)
            this.messageTokens.ship_part = getRandomName("shippart")
            this.messageTokens.body_part = getRandomName("bodypart")
            this.messageTokens.food = getRandomName("food")
            this.messageTokens.treatment = getRandomName("treatment")
            break

          case "killer-cyborg":
            this.messageTokens.science_tool = getRandomName("science-tool")
            this.messageTokens.body_part = getRandomName("bodypart")
            this.messageTokens.difficulty_description = getSimpleDifficultyDescription(this.difficulty)
            break

          case "bounty":
            this.messageTokens.planet_name = getRandomPlanetName()
            break

          case "burster":
            this.messageTokens.body_part = getRandomName("bodypart")
            break

          case "clones":
            this.messageTokens.body_part = getRandomName("bodypart")
            this.messageTokens.alien_description = getRandomName("adjective")
            this.messageTokens.difficulty_description = getSimpleDifficultyDescription(this.difficulty)
            break

          case "disease":
            this.messageTokens.disease_name = this.setRandomCrewDisease(this.crewMember)
            this.messageTokens.treatment = getRandomName("treatment")
            this.messageTokens.body_part = getRandomName("bodypart")
            this.messageTokens.difficulty_description = getMedicalDifficultyDescription(this.difficulty)
            break

          case "distress":
            this.messageTokens.captain = getRandomName("captainname")
            this.messageTokens.ship_name = getRandomName("shipname")
            this.messageTokens.ship_part = getRandomName("shippart")
            this.messageTokens.difficulty_description = getPlanetDifficultyDescription(this.difficulty)
            break

          case "fire":
            this.messageTokens.treatment = getRandomName("treatment")
            this.messageTokens.ship_part = getRandomName("shippart")
            this.messageTokens.kitchen_gadget = getRandomName("kitchen-gadget")
            break

          case "spacestation":
            this.messageTokens.station_name = getRandomName('station')
            break

          case "wormhole":
            this.messageTokens.difficulty_description = getWormholeDifficultyDescription(this.difficulty)
            break

          case "pleasure":
            this.messageTokens.planet_name = getRandomPlanetName()
            break

          case "race":
              this.messageTokens.difficulty_description = getSimpleDifficultyDescription(this.difficulty)
              break

          case "collision":
            this.messageTokens.difficulty_description = getCollisionDifficultyDescription(this.difficulty)
            break

          case "portal":
            this.messageTokens.difficulty_description = getSimpleDifficultyDescription(this.difficulty)
            this.messageTokens.food_torpedo = getRandomName("food", true)
            break

          case "attack":
            this.enemy = this.getRandomEnemy()
            this.ransom = this.getRansomAmount(this.enemy)
            this.messageTokens.enemy_name = this.enemy.name
            this.messageTokens.enemy_icon = this.enemy.icon
            this.messageTokens.ransom = `${this.ransom} credits`
            this.messageTokens.food = getRandomName("food")
            this.messageTokens.treatment = getRandomName("treatment")
            this.messageTokens.ship_part = getRandomName("shippart")
            this.messageTokens.difficulty_description = getCombatDifficultyDescription(this.enemy.challenge_rating)
            break

          case "planet":
            this.messageTokens.difficulty_description = getPlanetDifficultyDescription(this.difficulty)
            this.enemy = this.getRandomEnemy()
            this.messageTokens.enemy_name = this.enemy.name
            this.messageTokens.enemy_icon = this.enemy.icon
            break

          case "infestation":
            this.messageTokens.difficulty_description = getSimpleDifficultyDescription(this.difficulty)
            this.messageTokens.treatment = getRandomName("treatment")
            this.messageTokens.body_part = getRandomName("bodypart")
            this.messageTokens.ship_part = getRandomName("shippart")
            break

          case "fugitives":
            this.messageTokens.treatment = getRandomName("treatment")
            this.messageTokens.ship_part = getRandomName("shippart")
            break

          case "mutiny":
            this.messageTokens.airlock_count = this.currentGame.crew_airlock_count
            break

          case "trade":
            this.messageTokens.captain = getRandomName("captainname")
            this.messageTokens.ship_name = getRandomName("shipname")
            break

          case "murder":
            this.crewMember = getRandomRampagingCrewMember(this.currentShip)
            this.targetCrewMember = getRandomCrewMemberExcluding(this.crewMember.id, this.currentShip)

            this.pummelCrewMember(this.targetCrewMember) //TODO: requires two crew members...
            this.messageTokens.crew_member = this.crewMember.name
            this.messageTokens.favorite_food = this.crewMember.favorite_food
            this.messageTokens.target_crew_member = this.targetCrewMember.name
            this.messageTokens.robot_part = getRandomName("robotpart")
            this.messageTokens.treatment = getRandomName("treatment")
            break

          case "panic":
            setCrewPanic(this.crewMember)
            this.messageTokens.treatment = getRandomName("treatment")
            this.messageTokens.body_part = getRandomName("bodypart")
            break

          case "vampire":
            this.messageTokens.science_tool = getRandomName("science-tool")
            this.messageTokens.treatment = getRandomName("treatment")
            break

          case "lowfuel":
            this.enemy = this.getRandomEnemy()
            this.messageTokens.enemy_name = this.enemy.name
            this.messageTokens.enemy_icon = this.enemy.icon

            break
        }

        addMessage(resolveToString(this.currentEvent.description, this.messageTokens))
        return event
      }

      return null
    },

    getChoiceDescription (choice) {
      return resolveToString(choice.choice_text, this.messageTokens)
    },

    getChoiceList () {
      const choices = Choice.query().where((choice) => {
        if (choice.event_id == this.currentEvent.id
          && this.hasRequiredCredits(choice)
          && this.hasCrewMemberByPosition(choice.required_crew)
          && this.hasRequiredResources(choice)
          && this.hasRequiredSystems(choice)
          ) {
            return true
          } else {
            return false
          }
      }).all()

      return choices
    },

    hasRequiredSystems (choice) {
      if (this.currentShip.airlock_status == settings.AIRLOCK_STATUS_DAMAGED && choice.required_airlock) {
        return false
      }

      if (this.currentShip.weapons_system == settings.WEAPONS_SYSTEM_STATUS_DAMAGED && choice.required_weapons_system) {
        return false
      }

      if (this.currentShip.navigation_system == settings.NAVIGATION_SYSTEM_STATUS_DAMAGED && choice.required_navigation_system) {
        return false
      }

      return true
    },

    hasRequiredResources (choice) {
      return choice.required_medkits <= this.currentShip.med_kits
      && choice.required_repairkits <= this.currentShip.repair_kits
      && choice.required_food <= this.currentShip.food
    },

    hasRequiredCredits (choice) {
      if (choice.required_credits == -1) {
        return this.ransom <= this.currentShip.credits
      } else {
        return choice.required_credits <= this.currentShip.credits
      }
    },

    getResult (choice) {
      this.markEventResolved()
      this.performingSkillCrewMember = null

      // Add crew member names to message tokens
      getLivingCrewMembers(0, this.currentShip).forEach(crew => {
        this.messageTokens[crew.position.toLowerCase()] = crew.name
      })

      if (choice.achievement != '') {
        unlockAchievement(choice.achievement)
      }

      switch (choice.skill_type) {
        case "combat": {
          this.performingSkillCrewMember = getCrewMemberByPosition(settings.POSITION_WEAPONS, this.currentShip)
          const success = weaponsCheck(this.performingSkillCrewMember, this.enemy.challenge_rating)

          this.getRandomResult(choice, success)

          if (success) {
            const creditsGained = Math.floor(Math.random() * (100)) + this.difficulty * 10
            this.result.credit_cost = creditsGained
          }

          break
        }

        case "ransom": {
          this.getRandomResult(choice, true)
          this.result.credit_cost = -this.ransom
          break
        }

        case "weapons": { // Blasting through something
          this.performingSkillCrewMember = getCrewMemberByPosition(settings.POSITION_WEAPONS, this.currentShip)
          const success = weaponsCheck(this.performingSkillCrewMember, this.difficulty)
          this.getRandomResult(choice, success)
          this.messageTokens.body_part = getRandomName("bodypart")
          break
        }

        case "piloting": { // Piloting through something
          this.performingSkillCrewMember = getCrewMemberByPosition(settings.POSITION_PILOT, this.currentShip)
          const success = pilotingCheck(this.performingSkillCrewMember, this.difficulty)
          this.getRandomResult(choice, success)
          break
        }

        case "escape": { // Escape from an enemy
          this.performingSkillCrewMember = getCrewMemberByPosition(settings.POSITION_PILOT, this.currentShip)
          const success = pilotingCheck(this.performingSkillCrewMember, this.difficulty)

          if (success) {
            this.result = winningEscapeResults(this.enemy)
          } else {
            this.result = losingEscapeResults(this.enemy)
          }

          break
        }

        case "navigation": {
          this.performingSkillCrewMember = getCrewMemberByPosition(settings.POSITION_NAVIGATOR, this.currentShip)
          const success = navigationCheck(this.performingSkillCrewMember, this.difficulty)
          this.getRandomResult(choice, success)
          break
        }

        case "mechanics": {
          this.performingSkillCrewMember = getCrewMemberByPosition(settings.POSITION_MECHANIC, this.currentShip)
          const success = mechanicsCheck(this.performingSkillCrewMember, this.difficulty)
          this.getRandomResult(choice, success)
          break
        }

        case "cooking": {
          this.performingSkillCrewMember = getCrewMemberByPosition(settings.POSITION_COOK, this.currentShip)
          const success = cookingCheck(this.performingSkillCrewMember, this.difficulty)

          if (!('food' in this.messageTokens)) {
            this.messageTokens.food = getRandomName("food")
          }

          this.messageTokens.crew_favorite_food = this.crewMember.favorite_food
          this.getRandomResult(choice, success)
          break
        }

        case "science": {
          this.performingSkillCrewMember = getCrewMemberByPosition(settings.POSITION_SCIENTIST, this.currentShip)
          const success = scienceCheck(this.performingSkillCrewMember, this.difficulty)
          this.getRandomResult(choice, success)
          break
        }

        case "experiment": {
          this.performingSkillCrewMember = getCrewMemberByPosition(settings.POSITION_SCIENTIST, this.currentShip)
          const success = scienceCheck(this.performingSkillCrewMember, this.difficulty)
          this.getRandomResult(choice, success)

          if (success) {
            cureCrewDisease(this.crewMember)
          }
          break
        }

        case "medical": {
          this.performingSkillCrewMember = getCrewMemberByPosition(settings.POSITION_DOCTOR, this.currentShip)
          const success = medicalCheck(this.performingSkillCrewMember, this.difficulty)
          this.getRandomResult(choice, success)

          if (success) {
            this.crewMember = cureCrewDisease(this.crewMember)
          }
          break
        }

        case "pleasure": {
          const chance = Math.floor(Math.random() * 100)
          const results = Result.query().where((result) => {
            return result.choice_id == choice.id && result.probability > chance
          }).all()

          this.result = getRandomRecord(results)
          updateAllCrewDrunkenness(this.currentShip, settings.DRINK_POTENCY_WEAK)
          break
        }

        case "disease": {
          const chance = Math.floor(Math.random() * 100)
          const results = Result.query().where((result) => {
            return result.choice_id == choice.id && result.probability > chance
          }).all()

          this.result = getRandomRecord(results)

          if (this.result.result_type == 'failure' && this.result.special == 'disease') {
            this.messageTokens.disease_name = this.setRandomCrewDisease(this.crewMember)
          }
          break
        }

        default: {
          const chance = Math.floor(Math.random() * 100)
          const results = Result.query().where((result) => {
            return result.choice_id == choice.id && result.probability > chance
          }).all()

          this.result = getRandomRecord(results)
        }
      }

      addMessage(resolveToString(this.result.result_text, this.messageTokens), this.result.style)
      this.applyResults(this.result)

      // Perform special
      switch (this.result.special) {
        case "repair-airlock":
          updateShip(this.currentShip, {
            airlock_status: settings.AIRLOCK_STATUS_NORMAL,
          })
          break

        case "repair-waste":
          updateShip(this.currentShip, {
            waste_disposal: settings.WASTE_DISPOSAL_STATUS_NORMAL,
          })
          break

        case "createclone":
          addCrew(cloneCrewMember(this.crewMember, this.currentShip.id))
          break

        case "damage-airlock":
          damageShipAirlock(this.currentShip)
          break

        case "damage-waste":
          damageShipWasteDisposal(this.currentShip)
          break

        case "damage-navigation":
          damageShipNavigationSystem(this.currentShip)
          break

        case "damage-weapons":
          damageShipWeaponsSystem(this.currentShip)
          break

        case "airlockcount":
          updateGame(this.currentGame, { crew_airlock_count: this.currentGame.crew_airlock_count + 1 })
          break

        case "airlock-target":
          airlockCrewMember(this.currentGame, this.targetCrewMember, false)
          endCrewMurderousRampage(this.crewMember)
          addMessage(`${this.crewMember.name} murderous rampage has ended.`, 'success-message')
          break

        case "airlock-skill-crew":
          airlockCrewMember(this.currentGame, this.performingSkillCrewMember, false)
          endCrewMurderousRampage(this.crewMember)
          addMessage(`${this.crewMember.name} murderous rampage has ended.`, 'success-message')
          break

        case "kill-skill-crew":
          crewDeath(this.performingSkillCrewMember, this.currentGame)
          addMessage(`${this.performingSkillCrewMember.name} dies.`, 'lose-message')
          break

        case "damage-skill-crew":
          this.calculateCrewHealth(this.performingSkillCrewMember, this.result.health_cost)
          this.result.health_cost = 0 // Prevent this.crewMember from also taking damage
          break

        case "captain-dies":
          updateGame(this.currentGame, { captain_dead: true })
          break

        case "squash-mutiny":
          updateGame(this.currentGame, { crew_airlock_count: 0 })
          break

        case "food-truck-take-out":
          lockAchievement('HUNGRY')
          getLivingCrewMembers(0, this.currentShip).forEach(crew => {
            feedCrew(crew, crew.hunger, 1)
          })
          break

        case "clear-crew-hunger":
          getLivingCrewMembers(0, this.currentShip).forEach(crew => {
            feedCrew(crew, crew.hunger, 1)
          })
          break

        case "airlock-clear-crew-hunger":
          airlockCrewMember(this.currentGame, this.crewMember)
          //updateGame(this.currentGame, { crew_airlock_count: this.currentGame.crew_airlock_count + 1 })
          getLivingCrewMembers(0, this.currentShip).forEach(crew => {
            feedCrew(crew, crew.hunger, 1)
          })
          break

        case "lose-crew":
          updateCrew(this.crewMember, { ship_id: 0 })
          updateGame(this.currentGame, { crew_death_count: this.currentGame.crew_death_count + 1 })
          addMessage(`${this.crewMember.name} has left your crew.`, 'lose-message')
          break

        case "whole-crew-morale":
          getLivingCrewMembers(0, this.currentShip).forEach(crew => {
            this.updateCrewMorale(crew, this.result.morale_cost)
          })

          if (this.result.morale_cost > 0) {
            getRampagingCrewMembers(this.currentShip).forEach(crew => {
              endCrewMurderousRampage(crew)
              addMessage(`${crew.name} murderous rampage has ended.`, 'success-message')
            })
          }

          break

        case "sucker-punch":
          addMessage(updateCrewHealth(this.targetCrewMember, -(Math.floor(Math.random() * 10 + 10))), 'lose-message')
          endCrewMurderousRampage(this.crewMember)
          addMessage(`${this.crewMember.name} murderous rampage has ended.`, 'success-message')
          break

        case "end-rampage":
          endCrewMurderousRampage(this.crewMember)
          addMessage(`${this.crewMember.name} murderous rampage has ended.`, 'success-message')
          break

        case "end-panic":
          endCrewPanic(this.crewMember)
          break

        case "eye":
          this.crewMember = transformCrewIntoEvilEye(this.crewMember).then(
            (c) => {
              addMessage(`${c.name} has joined your crew.`, 'success-message')
              return c
          })
          break

        case "cyborg-join":
          createCyborgCrew(this.currentShip)
          break
      }

      if (this.result.distance_cost != 0) {
        this.updateOnlyDistance(this.result.distance_cost)
        const displayDistance = Math.abs(this.result.distance_cost)

        if (this.result.distance_cost < 0) {
          addMessage(`Traveled ${displayDistance} parsecs closer to your destination`, 'success-message')
        } else {
          addMessage(`Traveled ${displayDistance} parsecs away from your destination`, 'lose-message')
        }
      }

      if (this.crewMember != null) {
        this.calculateCrewHealth(this.crewMember, this.result.health_cost)
      }

      if (this.performingSkillCrewMember != null) {
        this.giveMoraleBonus(this.performingSkillCrewMember, this.result.morale_cost)
      }
      //this.awardPoints(this.result)
    },

    giveMoraleBonus (crew, morale_change) {
      switch (crew.hunger) {
        case settings.HUNGER_LEVEL_FULL:
          // Morale can go up or down when full
          this.updateCrewMorale(crew, morale_change)
          break

        case settings.HUNGER_LEVEL_PECKISH:
          // Morale might go up but can always go down
          if (morale_change > 0) {
            if (Math.floor(Math.random() * 100 < crew.health - 10)) {
              this.updateCrewMorale(crew, morale_change)
            }
          } else {
            this.updateCrewMorale(crew, morale_change)
          }
          break

        case settings.HUNGER_LEVEL_HUNGRY:
        case settings.HUNGER_LEVEL_STARVING:
          // Morale only goes down when hungry or starving
          if (morale_change < 0) {
            this.updateCrewMorale(crew, morale_change)
          }
          break
      }
    },

    getRandomResult (choice, isSuccessful) {
      const chance = Math.floor(Math.random() * 100)
      const results = Result.query().where((result) => {
        return result.choice_id == choice.id && result.probability > chance && result.result_type == (isSuccessful ? 'success' : 'failure')
      }).all()

      this.result = getRandomRecord(results)
      return this.result
    },

    awardPoints (result) {

      if (result.xp_gained > 0) {
        let totalScore = this.crewMember.xp + result.xp_gained

        GameState.update({
          where: this.currentGame.id,
          data: {
            xp: totalScore
          }
        })
        addMessage(`You gained ${result.xp_gained} points!`, "score")
      }

    },

    purchaseCrates (amount, cost) {
      if (this.currentShip.credits >= Math.abs(cost)) {
        const crates = this.calculateResourceChange('crate', this.currentShip.crates, amount, true)
        const credits = this.calculateCredits(this.currentShip.credits, cost)

        updateShip(this.currentShip, {
          crates: crates,
          credits: credits
        })

      } else {
        addMessage(`You don't have enough credits to purchase that.`, 'lose-message')
      }
    },

    purchaseRepairKits (amount, cost) {
      if (this.currentShip.credits >= Math.abs(cost)) {
        const repairKits = this.calculateResourceChange('repair kit', this.currentShip.repair_kits, amount, true)
        const credits = this.calculateCredits(this.currentShip.credits, cost)

        updateShip(this.currentShip, {
          repair_kits: repairKits,
          credits: credits
        })

      } else {
        addMessage(`You don't have enough credits to purchase that.`, 'lose-message')
      }
    },

    purchaseMedKits (amount, cost) {
      if (this.currentShip.credits >= Math.abs(cost)) {
        const medKits = this.calculateResourceChange('med kit', this.currentShip.med_kits, amount, true)
        const credits = this.calculateCredits(this.currentShip.credits, cost)

        updateShip(this.currentShip, {
          med_kits: medKits,
          credits: credits
        })
      } else {
        addMessage(`You don't have enough credits to purchase that.`, 'lose-message')
      }
    },

    purchaseFood (amount, cost) {
      if (this.currentShip.credits >= Math.abs(cost)) {
        const food = this.calculateResourceChange('food', this.currentShip.food, amount, true)
        const credits = this.calculateCredits(this.currentShip.credits, cost)

        updateShip(this.currentShip, {
          food: food,
          credits: credits
        })

        lockAchievement('HUNGRY')
      } else {
        addMessage(`You don't have enough credits to purchase that.`, 'lose-message')
      }
    },

    purchaseMoraleBoost (cost) {
      if (this.currentShip.credits >= Math.abs(cost)) {
        const credits = this.calculateCredits(this.currentShip.credits, cost)

        updateShip(this.currentShip, {
          credits: credits
        })
      } else {
        addMessage(`You don't have enough credits to purchase that.`, 'lose-message')
      }
    },

    sellRepairKits (amount, price) {
      if (this.currentShip.repair_kits >= amount) {
        const repairKits = this.calculateResourceChange('repair kit', this.currentShip.repair_kits, -amount, true)
        const credits = this.calculateCredits(this.currentShip.credits, price)

        updateShip(this.currentShip, {
          repair_kits: repairKits,
          credits: credits
        })
      } else {
        addMessage(`You don't have a repair kit to sell.`, 'lose-message')
      }
    },

    sellMedKits (amount, price) {
      if (this.currentShip.med_kits >= amount) {
        const medKits = this.calculateResourceChange('med kit', this.currentShip.med_kits, -amount, true)
        const credits = this.calculateCredits(this.currentShip.credits, price)

        updateShip(this.currentShip, {
          med_kits: medKits,
          credits: credits
        })

      } else {
        addMessage(`You don't have a med kit to sell.`, 'lose-message')
      }
    },

    sellFood (amount, price) {
      if (this.currentShip.food >= amount) {
        const food = this.calculateResourceChange('food', this.currentShip.food, -amount, true)
        const credits = this.calculateCredits(this.currentShip.credits, price)

        updateShip(this.currentShip, {
          food: food,
          credits: credits
        })
      } else {
        addMessage(`You don't have that much food.`, 'lose-message')
      }
    },

    sellErbiumCrystal (amount, price) {
      if (this.currentShip.erbium_crystals >= amount) {
        const crystals = this.calculateResourceChange('erbium crystal', this.currentShip.erbium_crystals, -amount)
        const credits = this.calculateCredits(this.currentShip.credits, price)

        updateShip(this.currentShip, {
          erbium_crystals: crystals,
          credits: credits
        })
      } else {
        addMessage(`You don't have a erbium crystal to sell.`, 'lose-message')
      }
    },

    sellAlienArtifact (amount, price) {
      if (this.currentShip.alien_artifacts >= amount) {
        const artifacts = this.calculateResourceChange('alien artifact', this.currentShip.alien_artifacts, -amount)
        const credits = this.calculateCredits(this.currentShip.credits, price)

        updateShip(this.currentShip, {
          alien_artifacts: artifacts,
          credits: credits
        })
      } else {
        addMessage(`You don't have a alien artifacts to sell.`, 'lose-message')
      }
    },

    getComMessageReward (choice) {
      const fuel = this.calculateStatus(this.currentShip.fuel_amount, choice.fuel_cost, "fuel")
      const hull = this.calculateStatus(this.currentShip.hull_status, choice.hull_cost, "hull")
      const weapons = this.calculateStatus(this.currentShip.weapon_status, choice.weapons_cost, "weapons")
      const engines = this.calculateStatus(this.currentShip.engine_status, choice.engines_cost, "engines")

      const repairKits = this.calculateResourceChange('repair kit', this.currentShip.repair_kits, choice.repair_kits_cost, true)
      const medKits = this.calculateResourceChange('med kit', this.currentShip.med_kits, choice.med_kits_cost, true)
      const erbiumCrystals = this.calculateResourceChange('erbium crystal', this.currentShip.erbium_crystals, choice.erbiumcrystal_cost, true)
      const food = this.calculateResourceChange('food', this.currentShip.food, choice.food_cost, true)
      const credits = this.calculateCredits(this.currentShip.credits, choice.credit_cost)

      updateShip(this.currentShip, {
        fuel_amount: fuel,
        hull_status: hull,
        weapon_status: weapons,
        engine_status: engines,
        repair_kits: repairKits,
        med_kits: medKits,
        food: food,
        erbium_crystals: erbiumCrystals,
        credits: credits
      })

      switch (choice.special) {
        case "eagle5":
          updateShip(this.currentShip, { icon: 'rv' })
          updateGame(this.currentGame, { secrets_found: this.currentGame.secrets_found + 1 })
          break

        case "falcon":
          updateShip(this.currentShip, { icon: 'starship-freighter', hull_status: 60, engine_status: 60, engine_efficiency: 0.45 })
          updateGame(this.currentGame, { secrets_found: this.currentGame.secrets_found + 1 })
          break

        case "starship":
          updateShip(this.currentShip, { icon: 'starship' })
          updateGame(this.currentGame, { secrets_found: this.currentGame.secrets_found + 1 })
          break

        case "ufo":
          updateShip(this.currentShip, { icon: 'ufo' })
          updateGame(this.currentGame, { secrets_found: this.currentGame.secrets_found + 1 })
          break

        case "HAL":
          updateCrew(getRandomCrewMember(this.currentShip), { icon: 'user-robot', name: 'HAL9000', home_planet: 'Urbana' })
          updateGame(this.currentGame, { secrets_found: this.currentGame.secrets_found + 1 })
          addMessage("Daisy, daisy.")
          break

        case "alien":
          updateCrew(getRandomCrewMember(this.currentShip), { icon: 'user-alien', species: settings.SPECIES_GREYS, name: getRandomName('alienname') })
          updateGame(this.currentGame, { secrets_found: this.currentGame.secrets_found + 1 })
          addMessage("Wha.. what happened?")
          break

        case "eye":
          updateCrew(getRandomCrewMember(this.currentShip), { icon: 'eye-evil', species: settings.SPECIES_EYE, name: getRandomName('alienname') })
          updateGame(this.currentGame, { secrets_found: this.currentGame.secrets_found + 1 })
          break

        case "secret":
          updateGame(this.currentGame, { secrets_found: this.currentGame.secrets_found + 1 })
          break
      }
    },

    applyResults (result) {
      const fuel = this.calculateStatus(this.currentShip.fuel_amount, result.fuel_cost, "fuel")
      const hull = this.calculateStatus(this.currentShip.hull_status, result.hull_cost, "hull")
      const weapons = this.calculateStatus(this.currentShip.weapon_status, result.weapons_cost, "weapons")
      const engines = this.calculateStatus(this.currentShip.engine_status, result.engines_cost, "engines")

      const repairKits = this.calculateResourceChange('repair kit', this.currentShip.repair_kits, result.repair_kits_cost, true)
      const medKits = this.calculateResourceChange('med kit', this.currentShip.med_kits, result.med_kits_cost, true)
      const erbiumCrystals = this.calculateResourceChange('erbium crystal', this.currentShip.erbium_crystals, result.erbiumcrystal_cost, true)
      const alienArtifacts = this.calculateResourceChange('alien artifact', this.currentShip.alien_artifacts, result.alien_artifact_cost, true)
      const food = this.calculateResourceChange('food', this.currentShip.food, result.food_cost, true)
      const credits = this.calculateCredits(this.currentShip.credits, result.credit_cost)

      updateShip(this.currentShip, {
        fuel_amount: fuel,
        hull_status: hull,
        weapon_status: weapons,
        engine_status: engines,
        repair_kits: repairKits,
        med_kits: medKits,
        food: food,
        erbium_crystals: erbiumCrystals,
        alien_artifacts: alienArtifacts,
        credits: credits
      })
    },

    updateCrew (crew) {
      if (crew.health === 0) {
        crewDeath(crew, this.currentGame)
        addStatusMessage(`Oh no! ${crew.name} died!`)
      } else {

        if (crew.status !== settings.STATUS_DEAD) {
          const foodConsumed = this.feedCrewMember()
          updateCrewHunger(crew, foodConsumed, this.currentGame)
        }

        if (crew.has_disease) {
          updateCrewDisease(crew, this.currentGame)

          if(passInfectionCalculation(crew)) {
            this.infectRandomCrewMember(crew)
          }
        }

        if (crew.morale == settings.MIN_MORALE && crew.species == settings.SPECIES_ROBOT && crew.status != settings.STATUS_RAMPAGE) {
          this.crewRampageCheck(crew)
        }

        updateCrewDrunkenness(crew)
      }
    },

    crewRampageCheck (crew) {
      if (Math.random() * 100 < settings.RAMPAGE_CHANCE) {
        setCrewMurderousRampage(crew)
        addStatusMessage(`Oh no! ${crew.name} has gone into a murderous rampage!`)
      }
    },

    feedCrewMember () {
      const foodConsumed = (this.currentShip.food < this.currentGame.food_ration_level ? this.currentShip.food : this.currentGame.food_ration_level)
      updateShip(this.currentShip, {food: this.currentShip.food - foodConsumed })

      return foodConsumed
    },

    infectRandomCrewMember (crew) {
      const crewToInfect = getRandomHealthyCrewMemberExcluding(crew.id, this.currentShip)

      if (crewToInfect != null) {
        const disease = getDiseaseById(crew.disease_id)
        setCrewDisease(crewToInfect, disease, 0)
        addStatusMessage(`Oh no! ${crewToInfect.name} got ${disease.name} from ${crew.name}.`)
      }
    },

    calculateResourceChange (resource, currentAmount, change, createMessage=true) {
      let newAmount = currentAmount + change

      if (newAmount < 0 || isNaN(newAmount)) {
        newAmount = 0
        change = -currentAmount // Example: currentAmt = 10, change = -15, actual change = -10 (since they only have 10 credits)
      }

      if (createMessage) {
        this.createChangeMessage(resource, currentAmount, newAmount, change)
      }

      return newAmount
    },

    createChangeMessage (resource, originalValue, currentValue, change) {
      if (originalValue != currentValue) {
        const plural = (Math.abs(change) > 1 && resource != 'food' ? 's' : '')

        if (change < 0) {
          addMessage(`Lose ${-change} ${resource}${plural}`, 'lose-message')
        }

        if (change > 0) {
          addMessage(`Gain ${change} ${resource}${plural}`, 'gain-message')
        }
      }
    },

    calculateCredits(currentAmount, change) {
      let newAmount = currentAmount + change

      if (newAmount < 0 || isNaN(newAmount)) {
        newAmount = 0
        change = -currentAmount // Example: currentAmt = 10, change = -15, actual change = -10 (since they only have 10 credits)
      }

      if (change < 0) {
        addMessage(`${change} credits`, 'lose-message')
      }

      if (change > 0) {
        addMessage(`+${change} credits`, 'gain-message')
      }

      return newAmount
    },

    calculateCrewHealth (crew, health_change) {
      let health = valueLimit(crew.health + health_change)

      if (health_change == -100) {
        addMessage(`${crew.name} dies`, 'lose-message')
      } else {
        if (health_change < 0 || isNaN(health_change)) {
          addMessage(`${crew.name} loses ${health_change}% health`, 'lose-message')
        }

        if (health_change > 0) {
          addMessage(`${crew.name} gains ${health_change}% health`, 'gain-message')
        }
      }

      if (health <= 0) {
        crewDeath(crew, this.currentGame)
      } else {
        updateCrew(crew, { health: health })
      }
    },

    updateFoodRationLevel (level) {
      GameState.update({
        where: this.currentGame.id,
        data: {
          food_ration_level: level
        }})

      addMessage(`Food ration level updated.`, '')
    },

    updateCrewMorale (crew, change) {
      if (crew.status == settings.STATUS_PANIC && change > 0) {
        // Crew cannot gain morale with status panic
        change = 0
      }

      let morale = crew.morale + change
      morale = (morale < settings.MIN_MORALE ? settings.MIN_MORALE : morale)
      morale = (morale > settings.MAX_MORALE ? settings.MAX_MORALE : morale)

      if (change > 0) { addMessage(`${crew.name}'s morale increased`, 'gain-message') }
      if (change < 0) { addMessage(`${crew.name}'s morale decreased`, 'lose-message') }

      Crew.update({
        where: crew.id,
        data: { morale: morale }
      })
    },

    calculateStatus (currentAmount, change, label) {
      let newAmount = currentAmount + change

      newAmount = (newAmount < 0 ? 0 : newAmount)
      newAmount = (newAmount > 100 ? 100 : newAmount)

      if (change < 0) {
        addMessage(`Lose ${change}% ${label}`, 'lose-message')
      }

      if (change > 0) {
        addMessage(`Gain ${change}% ${label}`, 'gain-message')
      }

      return newAmount
    },

    repairHull (kitsUsed) {
      const crewMechanic = getCrewMemberByPosition(settings.POSITION_MECHANIC, this.currentShip)
      const hullGained = repairShipHull(this.currentShip, kitsUsed, crewMechanic)
      addMessage(`You used ${kitsUsed} repair ${kitsUsed > 1 ? 'kits' : 'kit'}. Hull increased by ${hullGained}%.`, 'gain-message')
    },

    repairEngine (kitsUsed) {
      const crewMechanic = getCrewMemberByPosition(settings.POSITION_MECHANIC, this.currentShip)
      const engineGained = repairShipEngine(this.currentShip, kitsUsed, crewMechanic)
      addMessage(`You used ${kitsUsed} repair ${kitsUsed > 1 ? 'kits' : 'kit'}. Engines increased by ${engineGained}%.`, 'gain-message')

    },

    repairAirlock (kitsUsed) {
      const crewMechanic = getCrewMemberByPosition(settings.POSITION_MECHANIC, this.currentShip)
      const success = repairShipAirlock(this.currentShip, kitsUsed, crewMechanic)

      if (success) {
        addMessage(`${crewMechanic.name} repaired the airlock. One repair kit used.`, 'gain-message')
      } else {
        addMessage(`${crewMechanic.name} was unable to repair the airlock. One repair kit used.`, 'lose-message')
      }
    },

    repairSystem (systemName, kitsUsed) {
      const crewMechanic = getCrewMemberByPosition(settings.POSITION_MECHANIC, this.currentShip)
      let success = false

      switch (systemName) {
        case "airlock":
          success = repairShipAirlock(this.currentShip, kitsUsed, crewMechanic)
          break
        case "waste disposal":
          success = repairShipWasteDisposal(this.currentShip, kitsUsed, crewMechanic)
          break
        case "weapons system":
          success = repairShipWeaponsSystem(this.currentShip, kitsUsed, crewMechanic)
          break
        case "navigation system":
          success = repairShipNavigationSystem(this.currentShip, kitsUsed, crewMechanic)
          break
      }

      if (success) {
        addMessage(`${crewMechanic.name} repaired the ${systemName}. One repair kit used.`, 'gain-message')
      } else {
        addMessage(`${crewMechanic.name} was unable to repair the  ${systemName}. One repair kit used.`, 'lose-message')
      }
    },

    outpostEngineRepair (cost) {
      if (this.hasCredits(cost)) {
        const credits = this.calculateCredits(this.currentShip.credits, cost)
        updateShip(this.currentShip, { engine_status: 100, credits: credits })
      }
    },

    outpostHullRepair (cost) {
      if (this.hasCredits(cost)) {
        const credits = this.calculateCredits(this.currentShip.credits, cost)
        updateShip(this.currentShip, { hull_status: 100, credits: credits })
      }
    },

    outpostAirlockRepair (cost) {
      if (this.hasCredits(cost)) {
        const credits = this.calculateCredits(this.currentShip.credits, cost)
        updateShip(this.currentShip, { airlock_status: settings.AIRLOCK_STATUS_NORMAL, credits: credits })
      }
    },

    outpostWasteDisposalRepair (cost) {
      if (this.hasCredits(cost)) {
        const credits = this.calculateCredits(this.currentShip.credits, cost)
        updateShip(this.currentShip, { waste_disposal: settings.WASTE_DISPOSAL_STATUS_NORMAL, credits: credits })
      }
    },

    outpostWeaponsSystemRepair (cost) {
      if (this.hasCredits(cost)) {
        const credits = this.calculateCredits(this.currentShip.credits, cost)
        updateShip(this.currentShip, { weapons_system: settings.WEAPONS_SYSTEM_STATUS_NORMAL, credits: credits })
      }
    },

    outpostNavigationSystemRepair (cost) {
      if (this.hasCredits(cost)) {
        const credits = this.calculateCredits(this.currentShip.credits, cost)
        updateShip(this.currentShip, { navigation_system: settings.NAVIGATION_SYSTEM_STATUS_NORMAL, credits: credits })
      }
    },

    outpostRepairEverything (cost) {
      if (this.hasCredits(cost)) {
        const credits = this.calculateCredits(this.currentShip.credits, cost)
        updateShip(this.currentShip, {
          engine_status: 100,
          hull_status: 100,
          waste_disposal: settings.WASTE_DISPOSAL_STATUS_NORMAL,
          airlock_status: settings.AIRLOCK_STATUS_NORMAL,
          navigation_system: settings.NAVIGATION_SYSTEM_STATUS_NORMAL,
          weapons_system: settings.WEAPONS_SYSTEM_STATUS_NORMAL,
          credits: credits
        })
      }
    },

    outpostRefuel (cost, fuelAmount) {
      if (this.hasCredits(cost)) {
        addMessage(`Your ship's has been refueled`, 'gain-message')
        const credits = this.calculateCredits(this.currentShip.credits, -cost)
        updateShip(this.currentShip,  { fuel_amount: fuelAmount, credits: credits })
      }
    },

    outpostWeaponRecharge (cost) {
      if (this.hasCredits(cost)) {
        addMessage(`Your ship's weapons have been recharged`, 'gain-message')
        const credits = this.calculateCredits(this.currentShip.credits, cost)
        updateShip(this.currentShip,  { weapon_status: 100, credits: credits })
      }
    },

    hasCredits (cost) {
      if (this.currentShip.credits >= cost) {
        return true
      } else {
        addMessage(`You don't have enough credits.`, 'lose-message')
        return false
      }
    },

    getRandomEnemy () {
      const enemies = Enemy.query().all()
      return getRandomRecord(enemies)
    },

    getRansomAmount (enemy) {
      return Math.floor(Math.random() * (100)) + enemy.challenge_rating * 12
    },

    hireCrewMember (crew, cost) {
      const credits = this.calculateCredits(this.currentShip.credits, -cost)

      addMessage(`You hire ${crew.name} as your ship's ${crew.position}.`, '')
      updateShip(this.currentShip, { credits: credits })
      updateCrew(crew, { ship_id: this.currentShip.id })
    },

    hasCrewMemberByPosition (position) {
      if (position) {
        const crewMember = getCrewMemberByPosition(position, this.currentShip)

        if (crewMember != null && crewMember.status !== settings.STATUS_DEAD) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },

    setRandomCrewDisease (crew) {
      let disease = getRandomDisease()
      setCrewDisease(crew, disease, this.difficulty)

      this.crewMember = Crew.query().whereId(crew.id).first() // Update the current crew member
      return disease.name
    },

    pummelCrewMember (crew) {
      const healthChange = -Math.floor(Math.random() * 20 + 20)
      updateCrewHealth(crew, healthChange)
    },

    displayCrew (crew_id) {
      GameState.update({
        where: this.currentGame.id,
        data: { selected_crew_id: crew_id }
      })
    },

    isGameOver () {
      if (this.currentGame.distance_to_planet <= 0) {
        clearMessages()
        endGame(true, 'Congrats! You made it!')
        return true
      }

      if (getLivingCrewMembers(0, this.currentShip).length === 0) {
        clearMessages()
        endGame(false, "You can't run a ship without a crew. Better luck next time.")
        return true
      }

      if (this.currentShip.fuel_amount <= 0){
        clearMessages()
        endGame(false, "Out of gas...")
        return true
      }

      if (this.currentShip.engine_status <= 0) {
        clearMessages()
        endGame(false, "Your engine is shot. Hope you got the extended warranty.")
        return true
      }

      if (this.currentShip.hull_status <= 0) {
        clearMessages()
        endGame(false, "Bad news, your hull is breached.")
        return true
      }

      if (this.currentGame.captain_dead) {
        clearMessages()
        endGame(false, "A ship needs a captain.")
        return true
      }

      return false
    },

    divertWeaponPower (weaponPower) {
      const fuelGained = Math.floor(weaponPower/2)
      let fuel = this.currentShip.fuel_amount + fuelGained
      let weapons = this.currentShip.weapon_status - weaponPower

      if (fuel > 100) { fuel = 100}
      if (weapons < 0) { weapons = 0 }
      updateShip(this.currentShip, { fuel_amount: fuel, weapon_status: weapons })

      addMessage(`You divert ${weaponPower}% weapon power and gain ${fuelGained}% fuel.`, 'success-message')
    },

    useMedKit (crewId) {
      const crew = Crew.query().with('disease').whereId(crewId).first()
      const crewDoctor = getCrewMemberByPosition(settings.POSITION_DOCTOR, this.currentShip)
      const totalKits = valueLimit(this.currentShip.med_kits -1)

      updateShip(this.currentShip, { med_kits: totalKits })
      addMessage(`You used 1 med kit.`, '')

      let healthGained = Math.floor(Math.random() * 10) + 10

      if (crewDoctor) {
        // A crew doctor double healing
        const multipler = doctorHealingMultipler(crewDoctor)
        healthGained = healthGained * multipler

        if (crew.disease != null) {
            addMessage(`${crewDoctor.name} healed what ailed ${crewDoctor.id == crew.id ? 'them' : crew.name}.`, '')
        } else {
          const adjective = getRandomName('wound-adjective')

          if (crewDoctor.id == crew.id) {
            addMessage(`${crewDoctor.name} treated their own ${adjective} wounds.`, '')
          } else {
            addMessage(`${crewDoctor.name} treated ${crew.name}'s ${adjective} wounds.`, '')
          }
        }
      }

      addMessage(`${crew.name} is feeling ${getFeelingDescription(healthGained)}.`, '')
      cureCrewDisease(crew)
      updateCrewHealth(crew, healthGained)

      return healthGained
    }
  }
}
