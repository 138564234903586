<template>

<div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="boxes-stacked" />
        Purchase Supplies
      </h3>

      <div v-if="this.currentGame.status == 'start'">
        Repair kits can be used to repair the engines or hull of your ship. Med kits can be used to patch up the crew if injured or sick.
        Each crew member requires at least 1 food unit per day.
      </div>

      <p>You have <span class="game-info">{{ credits }}</span> credits.</p>

      <div id="output">
        <div class="choices">
          <h3 class="supply-heading"><font-awesome-icon icon="screwdriver-wrench" /> Repair Kits <span style="color: var(--green);">(</span>You have <span class="supply-level">{{ currentShip.repair_kits }}</span><span style="color: var(--green);">)</span></h3>
          <ul class="fa-ul">
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="purchase('repairkit', 1)">Purchase 1 repair kit [{{ getItemPrice('repairkit', 1) }} credits]</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="purchase('repairkit', 5)">Purchase 5 repair kits [{{ getItemPrice('repairkit', 5) }} credits]</a></li>
          </ul>

          <h3 class="supply-heading"><font-awesome-icon icon="kit-medical" /> Med Kits <span style="color: var(--green);">(</span>You have <span class="supply-level">{{ currentShip.med_kits }}</span><span style="color: var(--green);">)</span></h3>
          <ul class="fa-ul">
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="purchase('medkit', 1)">Purchase 1 med kit [{{ getItemPrice('medkit', 1) }} credits]</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="purchase('medkit', 5)">Purchase 5 med kits [{{ getItemPrice('medkit', 5) }} credits]</a></li>
          </ul>

          <h3 class="supply-heading"><font-awesome-icon icon="burger-soda" /> Food <span style="color: var(--green);">(</span>You have <span class="supply-level">{{ currentShip.food }}</span><span style="color: var(--green);">)</span></h3>
          <ul class="fa-ul">
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="purchase('food', 100)">Purchase 100 food units [{{ getItemPrice('food', 100) }} credits]</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="purchase('food', 500)">Purchase 500 food units [{{ getItemPrice('food', 500) }} credits]</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="purchase('food', 1000)">Purchase 1000 food units [{{ getItemPrice('food', 1000) }} credits]</a></li>
          </ul>
        </div>

        <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
          <span v-html="message.text"></span>
        </div>

        <div><a href="#" @click.prevent="returnToOutpost()">Return to Space Station</a></div>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../../mixins/GameMixin'
import { itemPurchasePriceCalculation } from '../../utils/formulas'

export default {
  name: "PurchaseSupplies",

  mixins: [
    GameMixin
  ],

  computed: {
    credits () {
      return this.currentShip.credits.toLocaleString()
    }
  },

  methods: {
    returnToOutpost () {
      this.clearMessages()
      this.changeDisplay("outpost")
    },

    purchase (item, quantity) {
      this.clearMessages()
      const cost = -this.getItemPrice(item, quantity)

      switch (item) {
        case 'repairkit':
          this.purchaseRepairKits(quantity, cost)
          break
        case 'medkit':
          this.purchaseMedKits(quantity, cost)
          break
        case 'food':
          this.purchaseFood(quantity, cost)
          break
      }
    },

    getItemPrice (item, quantity) {
      return itemPurchasePriceCalculation(item) * quantity
    }
  }
}
</script>
