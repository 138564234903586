<template>
  <div class="skill">
    <h4 class="skill-header with-hr">Skills</h4>

    <div class="skills-list tablet:4-grid">
      <div class="skill tooltip">
        Cooking:
        <span class="tooltiptext tooltip-top">Increases effectiveness of cooking and amount of morale gained from meals.</span>
      </div>
      <div class="skill-value">{{ crew.cooking }}</div>

      <div class="skill tooltip">
        Mechanics:
        <span class="tooltiptext tooltip-top">Increases effectiveness of repairs to hull and engine.</span>
      </div>
      <div class="skill-value">{{ crew.mechanics }}</div>

      <div class="skill tooltip">
        <span class="tooltiptext tooltip-top">Increases effectiveness of medical skill checks and healing from Med Kits.</span>
        Medical:
      </div>
      <div class="skill-value">{{ crew.medical }}</div>

      <div class="skill tooltip">
        Piloting:
        <span class="tooltiptext tooltip-top">Increases effectiveness of piloting checks.</span>
      </div>
      <div class="skill-value">{{crew.piloting}}</div>

      <div class="skill tooltip">
        Science:
        <span class="tooltiptext tooltip-top">Yeah! Science bitches!</span>
      </div>
      <div class="skill-value">{{ crew.science }}</div>

      <div class="skill tooltip">
        Navigation:
        <span class="tooltiptext tooltip-top">Increases effectiveness of navigation checks.</span>
      </div>
      <div class="skill-value">{{ crew.navigation }}</div>

      <div class="skill tooltip">
        Weapons:
        <span class="tooltiptext tooltip-top">Increases effectiveness of weapons in combat.</span>
      </div>
      <div class="skill-value">{{ crew.weapons }}</div>

      <div class="skill tooltip">Morale:
        <span class="tooltiptext tooltip-top">Provides a bonus or penalty to skill checks.</span>
      </div>
      <div class="skill-value">{{ crew.morale }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SkillList",

  props: {
    crew: Object,
  }
}
</script>
