<template>
  <div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="screwdriver-wrench" />
        Ship Repairs
      </h3>

      <div>Your ship's engine is at {{this.currentShip.engine_status}}% and the hull is at {{this.currentShip.hull_status}}%.</div>
      <div v-if="airlockRepairCost > 0">Your ship's airlock is malfunctioning.</div>
      <div v-if="!repairsNeeded">Your ship does not need repairs.</div>

      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        {{message.text}}
      </div>

      <div id="output">
        <div class="choices">
          <ul class="fa-ul">
            <li v-if="this.currentShip.engine_status < 100"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="repairEngine()">Repair the engine [{{this.engineRepairCost}} credits]</a></li>
            <li v-if="this.currentShip.hull_status < 100"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="repairHull()">Repair the hull [{{this.hullRepairCost}} credits]</a></li>
            <li v-if="airlockRepairCost > 0"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="repairAirlock()">Repair the airlock [{{this.airlockRepairCost}} credits]</a></li>
            <li v-if="wasteDisposalRepairCost > 0"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="repairWasteDisposal()">Repair Waste Disposal [{{this.wasteDisposalRepairCost}} credits]</a></li>
            <li v-if="weaponsSystemRepairCost > 0"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="repairWeaponsSystem()">Repair Weapons System [{{this.weaponsSystemRepairCost}} credits]</a></li>
            <li v-if="navigationSystemRepairCost > 0"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="repairNavigationSystem()">Repair Navigation System [{{this.navigationSystemRepairCost}} credits]</a></li>
            <li v-if="everythingRepairCost > 0"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="repairEverything()">Repair everything [{{this.everythingRepairCost}} credits]</a></li>
          </ul>

          <div><a href="#" @click.prevent="gotoRoom('outpost')">Return to Outpost</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../../mixins/GameMixin'
import { hullRepairCostCalculation, engineRepairCostCalculation, airlockRepairCostCalculation, wasteDisposalRepairCostCalculation, weaponsSystemRepairCostCalculation, navigationSystemRepairCostCalculation,  } from '../../utils/formulas'
import { addMessage, clearMessages } from '../../utils/message'

export default {
  name: "OutpostRepair",

  mixins: [
    GameMixin
  ],

  computed: {
    engineRepairCost () {
      return engineRepairCostCalculation(this.currentShip.condition, this.currentShip.engine_status)
    },

    hullRepairCost () {
      return hullRepairCostCalculation(this.currentShip.condition, this.currentShip.hull_status)
    },

    airlockRepairCost () {
      return airlockRepairCostCalculation(this.currentShip)
    },

    wasteDisposalRepairCost () {
      return wasteDisposalRepairCostCalculation(this.currentShip)
    },

    weaponsSystemRepairCost () {
      return weaponsSystemRepairCostCalculation(this.currentShip)
    },

    navigationSystemRepairCost () {
      return navigationSystemRepairCostCalculation(this.currentShip)
    },

    everythingRepairCost () {
      return hullRepairCostCalculation(this.currentShip.condition, this.currentShip.hull_status)
      + engineRepairCostCalculation(this.currentShip.condition, this.currentShip.engine_status)
      + airlockRepairCostCalculation(this.currentShip)
      + wasteDisposalRepairCostCalculation(this.currentShip)
      + weaponsSystemRepairCostCalculation(this.currentShip)
      + navigationSystemRepairCostCalculation(this.currentShip)
    },

    repairsNeeded () {
      return this.everythingRepairCost > 0
    }
  },

  methods: {
    gotoRoom (room) {
      clearMessages()
      this.changeDisplay(room)
    },

    repairEngine () {
      clearMessages()
      if (this.currentShip.credits >= this.engineRepairCost) {
        addMessage(`Your ship's engine has been fully repaired`, 'gain-message')
        this.outpostEngineRepair(-this.engineRepairCost)
       } else {
        addMessage("You don't have enough credits.", "lose-message")
      }
    },

    repairHull () {
      clearMessages()
      if (this.currentShip.credits >= this.hullRepairCost) {
        addMessage(`Your ship's hull has been fully repaired`, 'gain-message')
        this.outpostHullRepair(-this.hullRepairCost)
       } else {
        addMessage("You don't have enough credits.", "lose-message")
      }
    },

    repairAirlock () {
      clearMessages()
      if (this.currentShip.credits >= this.airlockRepairCost) {
        addMessage(`Your ship's airlock has been repaired`, 'gain-message')
        this.outpostAirlockRepair(-this.airlockRepairCost)
       } else {
        addMessage("You don't have enough credits.", "lose-message")
      }
    },

    repairWasteDisposal () {
      clearMessages()
      if (this.currentShip.credits >= this.wasteDisposalRepairCost) {
        addMessage(`Your ship's waste disposal has been repaired`, 'gain-message')
        this.outpostWasteDisposalRepair(-this.wasteDisposalRepairCost)
       } else {
        addMessage("You don't have enough credits.", "lose-message")
      }
    },

    repairWeaponsSystem () {
      clearMessages()
      if (this.currentShip.credits >= this.wasteDisposalRepairCost) {
        addMessage(`Your ship's Weapons System has been repaired`, 'gain-message')
        this.outpostWeaponsSystemRepair(-this.weaponsSystemRepairCost)
       } else {
        addMessage("You don't have enough credits.", "lose-message")
      }
    },

    repairNavigationSystem () {
      clearMessages()
      if (this.currentShip.credits >= this.wasteDisposalRepairCost) {
        addMessage(`Your ship's Navigation System has been repaired`, 'gain-message')
        this.outpostNavigationSystemRepair(-this.navigationSystemRepairCost)
       } else {
        addMessage("You don't have enough credits.", "lose-message")
      }
    },

    repairEverything () {
      clearMessages()
      if (this.currentShip.credits >= this.engineRepairCost + this.hullRepairCost) {
        addMessage(`Your ship has been fully repaired`, 'gain-message')
        this.outpostRepairEverything(-this.everythingRepairCost)
      } else {
        addMessage("You don't have enough credits.", "lose-message")
      }
    }
  }
}
</script>
