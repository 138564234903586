<template>
  <div class="console with-border with-border-radius game-start text-center" style="position: relative; overflow: hidden;">
    <h1 class="sr-only">Space Awesome</h1>
    <div class="home-scene size-xs tablet:size-sm laptop:size-md">
      <i class="fad fa-sparkles" data-fa-transform="left-250 down-90 rotate-20"></i>
      <i class="fad fa-planet-ringed" data-fa-transform="left-120 down-280 rotate-70"></i>
      <i class="fad fa-star-christmas fa-lg fa-spin" data-fa-transform="left-130 down-10"></i>
      <i class="fad fa-meteor" data-fa-transform="left-25 down-30 rotate-70"></i>
      <i class="fad fa-sparkles fa-lg" data-fa-transform="right-100 down-220 rotate-200"></i>
      <i class="fad fa-star-christmas" data-fa-transform="right-130"></i>
      <i class="fad fa-planet-ringed" data-fa-transform="right-250 down-30"></i>
    </div>
    <img class="margin-top-5xl margin-bottom-xl" src="../../assets/images/space-awesome-start2.svg" style="z-index: 10;" />
    <h2 class="size-xl laptop:size-2xl margin-x-xl margin-top-xl margin-bottom-lg" style="color: var(--yellow); text-transform: uppercase;">
      Adventures in Spac<a style="color: var(--yellow);text-decoration: none;cursor: text;" href="#" @click.prevent="seinfeld()">e</a>
      <span class="intro-secret" style="height: 20px;">{{ codeResult }}&nbsp;</span>
    </h2>

    <div v-if="this.currentShip">
      <p>
        You are the captain of the <span class="ship-name"><a class="ship-name" style="text-decoration: none;" href="#" @click.prevent="gameAwesome()">{{ this.currentShip.name }}</a></span>.
        You are about to embark
        on a long journey to the planet of {{ currentGame.destination_planet }}.  You must hire a crew and buy supplies for the long journey ahead.
      </p>

      <div class="start-game tablet:display-flex flex-content-evenly flex-items-center margin-top-xl">
        <div>
          <label class="display-inline">Game mode</label>
          <select v-model="selectedDifficulty" id="selectedDifficulty" style="width: 220px; height: 2em;" class="select-difficulty margin-x-md padding-x-sm padding-y-0">
            <option v-for="difficulty in this.difficulties"
              :key="difficulty.value"
              :value="difficulty.value">
                {{difficulty.name}}
            </option>
          </select>
        </div>

        <button @click="gameStart()" class="green subtle margin-y-lg laptop:margin-0">PRESS START <i class="fas fa-arrow-right fa-lg"></i> </button>
      </div>

      <div class="margin-top-2xl" style="color: var(--gray);">
        <a class="ship-name" style="text-decoration: none;cursor: default;" href="#" @click.prevent="family()">©</a> 2022 FONTICONS INC.
      </div>

      <div class="margin-top-2xl" style="color: var(--gray);font-size: var(--font-size-xs);">
        Space Awesome is created and powered by <a href="https://fontawesome.com" taget="_blank">Font Awesome</a>, the internet's most popular icon library and toolkit.
        Join millions of other devs, designers, and content creators and go make something awesome.
      </div>


      <div class="tablet:display-flex flex-content-evenly flex-items-center margin-top-xl"  style="color: var(--gray);font-size: var(--font-size-xs);">
        <span style="margin-left: 10px; width: 50%;text-align: left;"><a href="https://github.com/FortAwesome/spaceawesome-feedback" target="_blank">Feedback &amp; Issues</a></span>
        <span style="margin-right: 10px; width: 50%;text-align: right;"><a href="" @click.prevent="gotoRoom('releasenotes')">v{{ version }}</a></span>
      </div>

    </div>
  </div>
</template>

<script>
import GameState from '../../store/models/GameState'
import Ship from '../../store/models/Ship'
import settings from '../../utils/settings'
import { unlockAchievement } from '../../utils/achievements'
import { contraCrew, lostCrew, familyCrew, seinfeldCrew, awesomeCrew, bttfCrew } from '../../utils/specialcrews'
import { calculateDistanceToOutpost, getCratesByDifficulty, getStartingCredits, getTravelDistanceByDifficulty, updateGame } from '../../utils/game'
import { addStatusMessage } from '../../utils/message'
import { updateShip } from '../../utils/ship'

export default {
  name: "StartGame",

  data() {
    return {
      codeResult: '',
      position: 0,
      lostPosition: 0,
      bttfPosition: 0,
      codeEntered: false,
      selectedDifficulty: settings.GAME_DIFFICULTY_NORMAL,
      difficulties: [
        { name: settings.GAME_DIFFICULTY_EASY, selected: false, value: settings.GAME_DIFFICULTY_EASY },
        { name: settings.GAME_DIFFICULTY_NORMAL, selected: true, value: settings.GAME_DIFFICULTY_NORMAL },
        { name: settings.GAME_DIFFICULTY_HARD, selected: false, value: settings.GAME_DIFFICULTY_HARD },
        { name: settings.GAME_DIFFICULTY_EXPERT, selected: false, value: settings.GAME_DIFFICULTY_EXPERT },
        { name: settings.GAME_DIFFICULTY_FOREVER, selected: false, value: settings.GAME_DIFFICULTY_FOREVER },
      ]
    }
  },

  created () {
    document.addEventListener("keydown", event => {
      if (event.isComposing) {
        return
      }

      if (settings.KONAMI_CODE[this.position] == event.code) {
        this.position++
      }

      if (this.position == settings.KONAMI_CODE.length) {
        this.konamiCode()
      }

      if (settings.LOST_CODE[this.lostPosition] == event.code) {
        this.lostPosition++
      }

      if (this.lostPosition == settings.LOST_CODE.length) {
        this.lost()
      }

      if (settings.BACK_TO_THE_FUTURE_CODE[this.bttfPosition] == event.code) {
        this.bttfPosition++
      }

      if (this.bttfPosition == settings.BACK_TO_THE_FUTURE_CODE.length) {
        this.backToTheFuture()
      }
    })
  },

  computed: {
    version () {
      return settings.VERSION
    },

    currentGame () {
      return GameState.query().first()
    },

    currentShip () {
      return Ship.query().with('crew_members').first()
    }
  },

  methods: {
    gotoRoom (display) {
      GameState.update({
        where: this.currentGame.id,
        data: { active_display: display }
      })
    },

    gameAwesome () {
      updateGame(this.currentGame, { secrets_found: 1 })
      updateShip(this.currentShip, { name: 'SS Awesome' })
      awesomeCrew(this.currentShip)
      unlockAchievement('AWESOME')
      this.codeResult = 'Awesome!'
    },

    konamiCode () {
      if (this.codeEntered == false) {
        updateGame(this.currentGame, { secrets_found: 1 })
        updateShip(this.currentShip, { name: 'SS Red Falcon', med_kits: 30 })
        contraCrew(this.currentShip)
        unlockAchievement('KONAMI')
        this.codeResult = 'Konami Code!'
        this.codeEntered = true
        this.$plausible.trackEvent('konami-code')
      }
    },

    family () {
      if (this.codeEntered == false) {
        updateGame(this.currentGame, { secrets_found: 1 })
        familyCrew(this.currentShip)
        this.codeResult = 'Family Time'
        this.codeEntered = true
      }
    },

    seinfeld () {
      if (this.codeEntered == false) {
        updateGame(this.currentGame, { secrets_found: 1 })
        seinfeldCrew(this.currentShip)
        unlockAchievement('FESTIVUS')
        this.codeResult = 'Serenity Now!'
        this.codeEntered = true
      }
    },

    lost () {
      if (this.codeEntered == false) {
        updateGame(this.currentGame, { secrets_found: 1 })
        updateShip(this.currentShip, { name: 'Oceanic 815'})
        lostCrew(this.currentShip)
        unlockAchievement('LOST')
        this.codeResult = 'Previously on Lost...'
        this.codeEntered = true
        this.$plausible.trackEvent('lost-code')
      }
    },

    backToTheFuture () {
      if (this.codeEntered == false) {
        updateGame(this.currentGame, { secrets_found: 1 })
        updateShip(this.currentShip, { name: 'Delorean'})
        bttfCrew(this.currentShip)
        unlockAchievement('OUTTATIME')
        this.codeResult = 'Great Scott!'
        this.codeEntered = true
        this.$plausible.trackEvent('bttf-code')
      }
    },

    gameStart () {
      this.$plausible.trackEvent('start-game')
      const startingCredits = getStartingCredits(this.selectedDifficulty)
      const distanceToPlanet = getTravelDistanceByDifficulty(this.selectedDifficulty)
      const distanceToOutpost = calculateDistanceToOutpost(this.selectedDifficulty)
      const special_crates = getCratesByDifficulty(this.selectedDifficulty)

      updateGame(this.currentGame, {
        active_display: 'outpost',
        active: true,
        difficulty: this.selectedDifficulty,
        distance_to_planet: distanceToPlanet,
        distance_to_outpost: distanceToOutpost,
        special_crates: special_crates,
      })

      updateShip(this.currentShip, {credits: startingCredits})

      if (this.selectedDifficulty == settings.GAME_DIFFICULTY_FOREVER) {
        addStatusMessage(`Initiating journey.`)
      } else {
        addStatusMessage(`Initiating journey to ${this.currentGame.destination_planet}.`)
      }
    },
  }
}
</script>
