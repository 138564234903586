<template>

<div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="screwdriver-wrench" />
        Hull Repair
      </h3>

      <div>You have <span class="game-info">{{this.currentShip.repair_kits}}</span> repair kits. Each repair kit fixes between 5% and 15% of the hull.
      Having a mechanic on the crew improves the effectiveness of a Repair Kit (based on the mechanics skill).</div>

      <br>
      <div>Your ship's hull is at <span class="game-info">{{this.currentShip.hull_status}}%</span></div>

      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        <span v-html="message.text"></span>
      </div>

      <div id="output">
        <div class="choices" v-if="this.currentShip.repair_kits > 0">
          <h3 class="supply-heading">Options</h3>
          <ul class="fa-ul">
            <li v-if="this.currentShip.repair_kits > 0"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="makeRepairs(1)">Use one repair kit</a></li>
            <li v-if="this.currentShip.repair_kits > 1"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="makeRepairs(2)">Use two repair kits</a></li>
            <li v-if="this.currentShip.repair_kits > 4"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="makeRepairs(5)">Use five repair kits</a></li>
          </ul>
        </div>

        <div><a href="#" @click.prevent="gotoRoom('engineroom')">Return to Engine Room</a></div>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../../../mixins/GameMixin'
import { clearMessages, addMessage } from '../../../utils/message'
import { unlockAchievement } from '../../../utils/achievements'
import { getRandomChatByType } from '../../../utils/phrases'
import { getCrewMemberByPosition } from '../../../utils/crew'

export default {
  name: "HullRepair",

  mixins: [
    GameMixin
  ],

  computed: {
    crewMechanic () {
      return getCrewMemberByPosition('Mechanic', this.currentShip)
    },
  },

  methods: {
    gotoRoom (room) {
      clearMessages()
      this.changeDisplay(room)
    },

    makeRepairs (kitsUsed) {
      clearMessages()

      if (this.currentShip.hull_status == 100) {
        unlockAchievement('NOTBROKE')
        addMessage(getRandomChatByType(this.crewMechanic, 'over-repair'))
      }

      this.repairHull(kitsUsed)
    }
  }
}
</script>
