import Phrase from "../store/models/Phrase";
import { getRandomRecord } from "./helpers";
import settings from "./settings";


export function getRandomCaptainGreeting (crew, beforeGreeting = '') {
  var phrase = ''

  switch (crew.species) {
    case settings.SPECIES_GREYS:
      phrase = getRandomPhrase('greys-captain-greeting')
      break

    case settings.SPECIES_ROBOT:
      phrase = getRandomPhrase('robot-captain-greeting')
      break

    case settings.SPECIES_EYE:
      phrase = getRandomPhrase('evileye-captain-greeting')
      break

    case settings.SPECIES_AI:
      phrase = getRandomPhrase('ai-captain-greeting')
      break

    default:
      if (crew.status == settings.STATUS_PANIC) {
        phrase = getRandomPhrase(('panic-captain-greeting'))
      } else {
        switch (crew.name) {
          case "Michael":
            phrase = getRandomPhrase(('captain-greeting-michael'))
            break
          case "Wolf":
            phrase = getRandomPhrase(('captain-greeting-wolf'))
            break
          case "Dr. Brown":
            phrase = getRandomPhrase(('captain-greeting-doc-brown'))
            break
          case "Wash":
            phrase = getRandomPhrase(('captain-greeting-wash'))
            break
          case "Biff":
            phrase = getRandomPhrase(('captain-greeting-biff'))
            break
          case "Marty":
            phrase = getRandomPhrase(('captain-greeting-marty'))
            break
          case "Strickland":
            phrase = getRandomPhrase(('captain-greeting-strickland'))
            break

          default:
            phrase = getRandomPhrase((crew.morale <= -3 ? 'captain-greeting-bad' : 'captain-greeting'))
            break
        }
      }

      break
  }

  return `${crew.name} says <span class='crew-speech'>"${beforeGreeting}${phrase.text}"</span>`
}

export function getRandomChat (crew) {
  const phrase = getRandomPhrase(`${crew.position}-chat`)
  return `${crew.name} says <span class='crew-speech'>"${phrase.text}"</span>`
}

export function getRandomChatByType (crew, phraseType) {
  const phrase = getRandomPhrase(phraseType)
  return `${crew.name} says <span class='crew-speech'>"${phrase.text}"</span>`
}

export function getStarvingChat (crew) {
  const phrase = getRandomPhrase('starving')
  return `${crew.name} says <span class='crew-speech'>"${phrase.text}"</span>`
}

export function formatCrewPhrase (crew, phraseText) {
  return `${crew.name} says <span class='crew-speech'>"${phraseText}"</span>`
}

export function getRandomMerchantGreeting (npcName) {
  const phrase = getRandomPhrase('merchant-greeting')
  return `${npcName} says <span class='crew-speech'>"${phrase.text}"</span>`
}

export function formatNPCPhrase (npcName, phraseText) {
  return `${npcName} says <span class='crew-speech'>"${phraseText}"</span>`
}

export function getRandomPhrase (phraseType) {
  const phrases = Phrase.query().where((phrase) => {
    return phrase.phrase_type === phraseType
  }).all()

  return getRandomRecord(phrases)
}
