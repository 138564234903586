import { updateShip } from './ship'
import { addMessage } from './message'
import { valueLimit } from './helpers'
import { getCrewMemberByPosition, crewIconNameToPosition, transformCrewIntoRobot, transformCrewIntoAlien, transformCrewIntoMonkey, transformCrewIntoCrab,
  updateCrew, transformCrewIntoCat, changeToMadScientist, transformCrewIntoCowboy } from './crew'
import { updateGame } from './game'
import { unlockAchievement } from './achievements'
import settings from './settings'

function crewRobotTransformCheat(ship, cheat) {
  const position = crewIconNameToPosition(cheat.icon1)
  const crew = getCrewMemberByPosition(position, ship)

  if (crew != null) {
    addMessage(`${crew.name} transformed into a robot!`, 'gain-message')
    transformCrewIntoRobot(crew, ship)
  } else {
    addMessage(`You are missing a ${position}.`, 'lose-message')
  }
}

function crewAlienTransformCheat(ship, cheat) {
  const position = crewIconNameToPosition(cheat.icon1)
  const crew = getCrewMemberByPosition(position, ship)

  if (crew != null) {
    addMessage(`${crew.name} transformed into a alien!`, 'gain-message')
    transformCrewIntoAlien(crew, ship)
  } else {
    addMessage(`You are missing a ${position}.`, 'lose-message')
  }
}

function crewCowboyTransformCheat(ship, cheat) {
  const position = crewIconNameToPosition(cheat.icon1)
  const crew = getCrewMemberByPosition(position, ship)

  if (crew != null) {
    addMessage(`${crew.name} transformed into a cowboy! Giddy up!`, 'gain-message')
    transformCrewIntoCowboy(crew, ship)
  } else {
    addMessage(`You are missing a ${position}.`, 'lose-message')
  }
}

function crewMonkeyTransformCheat(ship, cheat) {
  const position = crewIconNameToPosition(cheat.icon1)
  const crew = getCrewMemberByPosition(position, ship)

  if (crew != null) {
    addMessage(`${crew.name} transformed into a monkey!`, 'gain-message')
    transformCrewIntoMonkey(crew, ship)
  } else {
    addMessage(`You are missing a ${position}.`, 'lose-message')
  }
}

function crewCatTransformCheat(ship, cheat) {
  const position = crewIconNameToPosition(cheat.icon1)
  const crew = getCrewMemberByPosition(position, ship)

  if (crew != null) {
    addMessage(`${crew.name} transformed into a cat!`, 'gain-message')
    transformCrewIntoCat(crew, ship)
  } else {
    addMessage(`You are missing a ${position}.`, 'lose-message')
  }
}

function crewCrabTransformCheat(ship, cheat) {
  const position = crewIconNameToPosition(cheat.icon1)
  const crew = getCrewMemberByPosition(position, ship)

  if (crew != null) {
    addMessage(`${crew.name} transformed into a crab!`, 'gain-message')
    transformCrewIntoCrab(crew, ship)
  } else {
    addMessage(`You are missing a ${position}.`, 'lose-message')
  }
}

function maxCrewStats(ship, cheat) {
  const position = crewIconNameToPosition(cheat.icon1)
  const crew = getCrewMemberByPosition(position, ship)

  if (crew != null) {
    updateCrew(crew, {
      piloting: 10,
      mechanics: 10,
      navigation: 10,
      medical: 10,
      weapons: 10,
      cooking: 10,
      science: 10,
    })
    addMessage(`${crew.name} knows everything!`, 'gain-message')

  } else {
    addMessage(`You are missing a ${position}.`, 'lose-message')
  }
}

function crewMadscientist (ship) {
  const crewScientist = getCrewMemberByPosition(settings.POSITION_SCIENTIST, ship)

  if (crewScientist != null) {
    changeToMadScientist(crewScientist)
  } else {
    addMessage(`You are missing a scientist.`, 'lose-message')
  }
}

function crewMoralBoost (ship, cheat) {
  const position = crewIconNameToPosition(cheat.icon1)
  const crew = getCrewMemberByPosition(position, ship)

  if (crew != null) {
    addMessage(`${crew.name} is feeling awesome!`, 'gain-message')
    updateCrew(crew, { morale: 5 })
  } else {
    addMessage(`You are missing a ${position}.`, 'lose-message')
  }
}

export function unlockCheat (game, ship, cheat) {
  addMessage(cheat.name)
  updateGame(game, { cheats_unlocked: game.cheats_unlocked + 1 })
  unlockAchievement('CHEATER')

  switch (cheat.code) {
    case 'food':
      updateShip(ship, { food: ship.food + cheat.value })
      addMessage(`Gain ${cheat.value} food`, 'gain-message')
      break

    case 'superstats':
      maxCrewStats(ship, cheat)
      break

    case "monkey":
      crewMonkeyTransformCheat(ship, cheat)
      break

    case "crab":
      crewCrabTransformCheat(ship, cheat)
      break

    case "cat":
      crewCatTransformCheat(ship, cheat)
      break

    case "robot":
      crewRobotTransformCheat(ship, cheat)
      break

    case "alien":
      crewAlienTransformCheat(ship, cheat)
      break

    case "cowboy":
      crewCowboyTransformCheat(ship, cheat)
      break

    case "fuel":
      updateShip(ship, { fuel_amount: valueLimit(ship.fuel_amount + cheat.value) })
      addMessage(`Fuel increased by ${cheat.value}%`, 'gain-message')
      break

    case "medkit":
      updateShip(ship, { med_kits: ship.med_kits + cheat.value })
      addMessage(`Gain ${cheat.value} med kits`, 'gain-message')
      break

    case "repairkit":
      updateShip(ship, { repair_kits: ship.repair_kits + cheat.value })
      addMessage(`Gain ${cheat.value} repairs kits`, 'gain-message')
      break

    case "credits":
      updateShip(ship, { credits: ship.credits + cheat.value })
      addMessage(`Gain ${cheat.value} credits`, 'gain-message')
      break

    case "erbium":
      updateShip(ship, { erbium_crystals: ship.erbium_crystals + cheat.value })
      addMessage(`Gain ${cheat.value} erbium crystals`, 'gain-message')
      break

    case "artifact":
      updateShip(ship, { alien_artifacts: ship.alien_artifacts + cheat.value })
      addMessage(`Gain ${cheat.value} alien artifacts`, 'gain-message')
      break

    case "madscience":
      crewMadscientist(ship)
      break

    case "moralboost":
      crewMoralBoost(ship, cheat)
      break

  }
}