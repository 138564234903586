import Vue from 'vue'
import App from './App.vue'
import store from './store'

import { VuePlausible } from 'vue-plausible'
import VueCookies from 'vue-cookies'

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

library.add(fas)
library.add(far)
library.add(fal)
library.add(fad)
library.add(fab)
library.add(fat)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.config.productionTip = false

Vue.use(VueCookies)
Vue.use(VuePlausible, {
  domain: 'spaceawesome.io',
  apiHost: 'https://plausible.io',
  trackLocalhost: false
})

dom.watch()

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
