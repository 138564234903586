<template>

<div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="burger-fries" />
        Cantina
      </h3>

      <p>
        The dimly-lit cantina is known for its strong drinks, hot tunes, and occasional outbreaks of violence.
        It's a great place to cheer up weary crew members. You have <span class="game-info">{{ credits }}</span> credits.
      </p>

      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        {{message.text}}
      </div>

      <ul class="fa-ul">
        <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="smallBoost(-50)">Buy a round of the cheap stuff [50 credits]</a></li>
        <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="largeBoost(-200)">Buy a round of the good stuff [200 credits]</a></li>
      </ul>

      <div><a href="#" @click.prevent="gotoRoom('outpost')">Return to Outpost</a></div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../../mixins/GameMixin'
import { clearMessages } from '../../utils/message'
import { cantinaMinorEvent } from '../../utils/minorevent'
import { smallMoraleBoost, largeMoraleBoost, updateAllCrewDrunkenness } from '../../utils/crew'
import settings from '../../utils/settings'

export default {
  name: "Cantina",

  mixins: [
    GameMixin
  ],

  computed: {
    credits () {
      return this.currentShip.credits.toLocaleString()
    }
  },

  methods: {
    gotoRoom (room) {
      clearMessages()
      this.changeDisplay(room)
    },

    smallBoost (amount) {
      this.clearMessages()
      this.purchaseMoraleBoost(amount)
      smallMoraleBoost(this.currentShip)
      updateAllCrewDrunkenness(this.currentShip, settings.DRINK_POTENCY_WEAK)
      cantinaMinorEvent(this.currentGame, this.currentShip)
    },

    largeBoost (amount) {
      this.clearMessages()
      this.purchaseMoraleBoost(amount)
      largeMoraleBoost(this.currentShip)
      updateAllCrewDrunkenness(this.currentShip, settings.DRINK_POTENCY_MEDIUM)
      cantinaMinorEvent(this.currentGame, this.currentShip)
    }

  }
}
</script>
